import React, { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { uploadImage } from "../../app/services/upload.service";

const TextEditor = (props) => {
  const editorRef = useRef(null);

  const [error, setError] = useState(null);

  const handleInit = (_event, editor) => {
    editorRef.current = editor;
    props.onReady(editor);
  };

  const handleLoadError = (error) => {
    console.error('TinyMCE failed to load:', error);
  };

  const handleImageUpload = async (blobInfo, progress) => {
    return new Promise(async (resolve, reject) => {
      const file = blobInfo.blob();
      const fileName = `appraisal-photos/${blobInfo.filename()}`;
      const fileData = { bucketName: 'ctn-uploads', keyName: fileName };

      try {
        const presignedUrlResponse = await uploadImage(fileData);
        
        if (!presignedUrlResponse || !presignedUrlResponse.url) {
          throw new Error('Failed to get pre-signed URL');
        }

        const uploadUrl = presignedUrlResponse.url;
        

        await fetch(uploadUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
          },
        });

        const imgURL = `https://ctn-uploads.s3.ap-northeast-1.amazonaws.com/${fileName}`;

        resolve(imgURL);
      } catch (error) {
        console.error('Image upload error:', error);
        reject(error.message || 'Image upload failed');
        setError('Failed to upload image. Please try again.');
      }
    });
  };


  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <Editor
        apiKey="a3kcubtm1lcrxdvkuf4c33vrge4xb1p33izfwiihjwpz9qwb"
        onInit={handleInit}
        onLoadError={handleLoadError}
        initialValue={props.content}
        init={{
          height: 500,
          menubar: false,
          plugins:
            "preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen link codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars linkchecker emoticons",
          mobile: {
            plugins:
              "preview  importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen link codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars linkchecker emoticons",
          },
          menu: {
            tc: {
              title: "Comments",
              items: "addcomment showcomments deleteallconversations",
            },
          },
          menubar: "file edit view insert format tools table tc help",
          toolbar:
            "undo redo | aidialog aishortcuts | blocks fontsizeinput | bold italic | align numlist bullist | link image | table media pageembed | lineheight  outdent indent | strikethrough forecolor backcolor formatpainter removeformat | charmap emoticons checklist | code fullscreen preview | save print | pagebreak anchor codesample footnotes mergetags | addtemplate inserttemplate | addcomment showcomments | ltr rtl | spellcheckdialog a11ycheck", // Note: if a toolbar item requires a plugin, the item will not present in the toolbar if the plugin is not also loaded.
          autosave_ask_before_unload: true,
          autosave_interval: "30s",
          autosave_prefix: "{path}{query}-{id}-",
          autosave_restore_when_empty: false,
          autosave_retention: "2m",
          image_advtab: true,
          images_upload_handler: handleImageUpload,
          automatic_uploads: true,
          file_picker_types: 'image',
          object_resizing: true, // Allow image resizing
          resize: true, // Optional: Allows general resizing in the editor
          image_dimensions: true,
          paste_data_images: true,
          image_caption: true,
          image_title: true,
          extended_valid_elements: "span[*],i[*],em[*],strong[*],a[*],img[*]",
          entity_encoding: 'raw',
          entities: '160,nbsp,38,amp,60,lt,62,gt',
          encoding: 'xml',
          paste_data_images: true,
          smart_paste: true,
          paste_remove_styles_if_webkit: false,
          paste_merge_formats: true,
          nonbreaking_force_tab: true,
          typography_rules: [
            "common/punctuation/quote",
            "en-US/dash/main",
            "common/nbsp/afterParagraphMark",
            "common/nbsp/afterSectionMark",
            "common/nbsp/afterShortWord",
            "common/nbsp/beforeShortLastNumber",
            "common/nbsp/beforeShortLastWord",
            "common/nbsp/dpi",
            "common/punctuation/apostrophe",
            "common/space/delBeforePunctuation",
            "common/space/afterComma",
            "common/space/afterColon",
            "common/space/afterExclamationMark",
            "common/space/afterQuestionMark",
            "common/space/afterSemicolon",
            "common/space/beforeBracket",
            "common/space/bracket",
            "common/space/delBeforeDot",
            "common/space/squareBracket",
            "common/number/mathSigns",
            "common/number/times",
            "common/number/fraction",
            "common/symbols/arrow",
            "common/symbols/cf",
            "common/symbols/copy",
            "common/punctuation/delDoublePunctuation",
            "common/punctuation/hellip",
          ],
          typography_ignore: ["code"],
          language: "ja"
        }}
      />
    </div>
  );
};

export default TextEditor;
