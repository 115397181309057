import React, { useState } from 'react';
import { Form, Input, Button, Alert, Card } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useResetPasswordMutation } from '../../app/services/password.api';
import logo from '../../assets/images/logo-top.png'; 
import './PasswordResetView.css';

const PasswordResetView = () => {
  const [searchParams] = useSearchParams();
  const tokenFromUrl = searchParams.get('token');
  const userIdFromUrl = searchParams.get('userId'); 
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setMessage('');

    if (formData.password !== formData.confirmPassword) {
      setMessage('パスワードが一致しません。!');
      return;
    }

    const requestData = {
      token: tokenFromUrl, 
      userId: userIdFromUrl,
      password: formData.password,
    };

    try {
      const result = await resetPassword(requestData).unwrap();
      setMessage('パスワードのリセットに成功しました.');
      if (result.message) {
        //TODO make the URL dynamic
        window.location.href = 'https://development.da9f2us1k1n15.amplifyapp.com/login';
      }
    } catch (error) {
      //TODO make the URL dynamic
      // Check if the status is 200, even if there's a parsing error
      if (error.originalStatus === 200) {
        setMessage('パスワードのリセットに成功しました.');
        window.location.href = 'https://development.da9f2us1k1n15.amplifyapp.com/login';
      } else {
        setMessage('エラーが発生しました。もう一度お試しください.');
      }
    }
  };

  return (
    <div className="reset-password-container">
      <Card className="reset-password-card" bordered={false}>
        <div className="logo-wrapper">
          <img src={logo} alt="CTN logo" className="reset-password-logo" />
        </div>
        <h2 className="reset-password-title">パスワード設定</h2>
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={formData}
        >
          <Form.Item
            label="新しいパスワード"
            name="password"
            rules={[{ required: true, message: '新しいパスワードを入力してください' }]}
          >
            <Input.Password
              placeholder="新しいパスワード"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="reset-password-input"
              size="large"
            />
          </Form.Item>
          <Form.Item
            label="パスワードを再入力"
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              { required: true, message: 'パスワードを再入力してください' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('2つのパスワードが一致しません!'));
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="パスワードを再入力"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              className="reset-password-input"
              size="large"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              className="reset-password-button"
              loading={isLoading}
              size="large"
            >
              {isLoading ? 'リセットしています...' : 'パスワードを設定する'}
            </Button>
          </Form.Item>
        </Form>
        {message && (
          <Alert
            message={message}
            type={message.includes('成功') ? 'success' : 'error'}
            showIcon
            className="reset-password-alert"
          />
        )}
      </Card>
    </div>
  );
};

export default PasswordResetView;
