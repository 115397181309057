import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const passwordResetApi = createApi({
    reducerPath: "passwordResetApi",
    baseQuery: fetchBaseQuery({
      baseUrl: `${process.env.REACT_APP_ENDPOINT_V2}`,
    }),
    endpoints: (builder) => ({
      resetPassword: builder.mutation({
        query: ({ token, userId, password }) => {
          return {
            url: `/accounts/password-reset?userId=${userId}&token=${token}&password=${encodeURIComponent(password)}`, 
            method: 'POST',
          };
        },
      }),
    }),
  });
  
  export const { useResetPasswordMutation } = passwordResetApi;
  
  