import React, { useEffect, useRef, useState } from "react";
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  Container,
  Stack,
  IconButton,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import TextEditor from "../../components/text-editor";
import { MainWrapper, NavigatePage } from "../../components";
import {
  useCreateNoticeMutation,
  useUpdateNoticeMutation,
} from "../../app/services/notices.api";
import { useLocation, useNavigate } from "react-router-dom";

const categories = ["GENERAL", "BUYER", "SELLER"];
const categoryLabels = {
  GENERAL: "全ユーザー",
  BUYER: "買取店",
  SELLER: "エンドユーザー",
};

const CreateNoticePage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [content, setContent] = useState("");
  const editorInstance = useRef(null);
  const [createNoticeFunc] = useCreateNoticeMutation();
  const [updateNoticeFunc] = useUpdateNoticeMutation();

  useEffect(() => {
    if (state && state.notice) {
      setTitle(state.notice.title);
      setCategory(state.notice.category);
      setContent(state.notice.content);
    }
  }, [state]);
  const handleSubmit = (event) => {
    event.preventDefault();

    const params = {
      id: state?.notice?.id,
      title,
      category,
      content: editorInstance.current.getContent(),
    };
    if (state?.type === "EDIT") {
      updateNoticeFunc(params).then(() => {
        navigate("/console/dashboard?tab=2", {
          state: {
            selectedTabDashboard: 2,
            previousPage: "/console/appraisals",
          },
        });
      });
    } else {
      createNoticeFunc(params).then(() => {
        navigate("/console/dashboard?tab=2", {
          state: {
            selectedTabDashboard: 2,
            previousPage: "/console/appraisals",
          },
        });
      });
    }
  };

  const handleClose = () => {
    navigate("/console/dashboard?tab=2", {
      state: {
        selectedTabDashboard: 2,
        previousPage: "/console/appraisals",
      },
    });
  };

  return (
    <MainWrapper>
      <NavigatePage />
      <div className="create-notice-panel ctn-wrapper relative">
      <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[700],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Container maxWidth="lg">
          <h1>{state?.type === "EDIT" ? "編集" : "新規作成"}</h1>
          <hr />
          <form onSubmit={handleSubmit}>
            <Stack
              direction={"row"}
              spacing={5}
              sx={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <TextField
                sx={{ marginBottom: "10px",width: '70%' }}
                label="タイトル"
                variant="outlined"
                margin="normal"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <FormControl
                variant="outlined"
                margin="normal"
                sx={{ marginBottom: "30px",width: '30%' }}
              >
                <InputLabel>ユーザータイプ</InputLabel>
                <Select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  label="Category"
                >
                  {categories.map((cat) => (
                    <MenuItem key={cat} value={cat}>
                       {categoryLabels[cat]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <TextEditor
              content={content}
              onReady={(editor) => {
                editorInstance.current = editor;
              }}
            />
            <Button
              sx={{ marginTop: "30px" }}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              {state?.type === "EDIT" ? "編集" : "追加"}
            </Button>
          </form>
        </Container>
      </div>
    </MainWrapper>
  );
};

export default CreateNoticePage;
