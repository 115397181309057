import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const TableLoadingSkeleton = ({ rows = 5, columns = 5 }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {Array(columns).fill().map((_, index) => (
              <TableCell key={index}>
                <Skeleton height={20} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array(rows).fill().map((_, rowIndex) => (
            <TableRow key={rowIndex}>
              {Array(columns).fill().map((_, colIndex) => (
                <TableCell key={colIndex}>
                  <Skeleton height={20} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableLoadingSkeleton;
