import { ConfigProvider } from "antd";
import AntdSwitch from "antd/es/switch"
import React from "react";

export const Switch = (props) => {
  return (
    <ConfigProvider
      theme={{
        token: {
            colorPrimary: "#2acc42"
        },
      }}
    >
      <AntdSwitch  checked={props.checked} onChange={props.onChange} /> 
    </ConfigProvider>
  );
};
