import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const invoicesApi = createApi({
  reducerPath: "invoicesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_ENDPOINT}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Invoices", "CompanyInvoices", "InvoiceDetail", "InvoiceForPDF"],
  endpoints: (builder) => ({
    getInvoices: builder.query({
      query: (params) => ({
        url: `/invoice`,
        method: "GET",
        params: {
          name: params.name,
          billing: params.billing,
          debit: params.debit,
          deleted: params.deleted,
          isBetween: params.isBetween,
          fromYear: params.fromYear,
          toYear: params.toYear,
          fromMonth: params.fromMonth,
          toMonth: params.toMonth,
          fromDay: params.fromDay,
          toDay: params.toDay
        },
        headers: {
          id: params.id,
          year: params.year,
          month: params.month,
          page: params.page,
          size: params.size
        },
      }),
      providesTags: [{ type: "Invoices" }],
    }),
    getInvoicesByCompany: builder.query({
      query: ({ companyId, year }) => ({
        url: `/invoice/year/${companyId}`,
        method: "GET",
        headers: {
          year: year,
        },
      }),
      providesTags: [{ type: "CompanyInvoices" }],
    }),
    getInvoiceDetails: builder.query({
      query: ({ shopId, year, month, type, attribute, main, mainId, isBetween, fromYear, toYear, fromMonth, toMonth, fromDay, toDay }) => {
        const params = { isBetween };
        if (isBetween) {
          Object.assign(params, {
            fromYear,
            toYear,
            fromMonth,
            toMonth,
            fromDay,
            toDay
          });
        }
        return {
          url: `/invoice/detail/${shopId}/${main}/${mainId}`,
          method: "GET",
          params,
          headers: {
            year,
            month,
            type,
            attribute,
          },
        };
      },
      providesTags: [{ type: "InvoiceDetail" }],
    }),
    updateBillingStatus: builder.mutation({
      query: ({ companyId, body }) => ({
        url: `/invoice/year/${companyId}`,
        method: "PATCH",
        body: body,
        headers: {},
      }),
      invalidatesTags: ["Invoices"],
    }),
    updateDepositedStatus: builder.mutation({
      query: ({ year, month, shopId }) => ({
        url: `/invoice/year/deposit/${shopId}`,
        method: "PATCH",
        body: {
          year,
          month,
        },
      }),
      invalidatesTags: ["Invoices"],
    }),
    reInvoice: builder.mutation({
      query: ({ year, month, shopId, selectedDate }) => ({
        url: `/invoice/reInvoice/${shopId}/${year}/${month}/${selectedDate}`,
        method: "GET",
      }),
      invalidatesTags: ["CompanyInvoices"],
    }),
    generateInvoiceBill: builder.query({
      query: ({ shopId, year, month }) => ({
        url: `/invoice/${shopId}/${year}/${month}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }),
      providesTags: [{ type: 'InvoiceForPDF' }]
    })
  }),
});

export const {
  useGetInvoicesQuery,
  useLazyGetInvoicesQuery,
  useGetInvoicesByCompanyQuery,
  useLazyGetInvoicesByCompanyQuery,
  useGetInvoiceDetailsQuery,
  useLazyGetInvoiceDetailsQuery,
  useUpdateBillingStatusMutation,
  useUpdateDepositedStatusMutation,
  useReInvoiceMutation,
  useGenerateInvoiceBillQuery
} = invoicesApi;
