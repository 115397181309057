// import { useState } from "react";
// import Button from "../../components/button";
// import Text from "../../components/text";
import Report from "./Report";
import report_data from "./report_data.json";
import { useBackend } from "../../hooks/useBackend";
import { useEffect, useState } from "react";

// import Input from "../../components/input";

export default function ReportList(props) {
    // const [clicked, setClicked] = useState({on : false, off: true});
    // const [editing, setEditing] = useState(false);
    // const edit = () => {
    //     setEditing(!editing);
    // }
    // const onClickButton = () => { }

    return (
        <>
        {/* <div className="filter flex">
            <Text value="フィルタ" header />
            <div className="space_around_small"> <Button small label="未対応" color={"red"} has_state  {...clicked} onClick={onClickButton} /> </div>
            <div className="space_around_small"> <Button small label="対応中" color={"yellow"} has_state {...clicked} onClick={onClickButton} /> </div>
            <div className="space_around_small"> <Button small label="アポ確定" color={"blue"} has_state {...clicked} onClick={onClickButton} /> </div>
            <div className="space_around_small"> <Button small label="マッチング済" color={"gray"} has_state {...clicked} onClick={onClickButton} /> </div>
            <div className="filter_input"> <Input label="買取店" narrow/> </div>
            <div className="filter_input"> <Input label="キーワード" narrow/> </div>
        </div> */}
        <div className="report_list">
            {props?.data?.map(e => <Report data={e}/>)}
        </div>
        </>
    );
}