import React, { useEffect } from "react";
import { useLazyGetNoticesQuery } from "../../app/services/notices.api";
import { TableLoadingSkeleton } from "../../components";
import NoticesTable from "./table";

const SellerNoticePanel = () => {
  const [fetchNotices, { data, isLoading }] = useLazyGetNoticesQuery();

  useEffect(() => {
    const params = {
      page: 0,
      size: 100,
      category: "SELLER"
    };
    fetchNotices(params);
  }, []);

  return (
    <div>
      {isLoading ? <TableLoadingSkeleton /> : <NoticesTable data={data} />}
    </div>
  );
};

export default SellerNoticePanel;
