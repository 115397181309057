import './_app.scss';
import Routing from './features/routing/route';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div>
    <Routing />
    <Toaster />
    </div>
  );
}

export default App;
