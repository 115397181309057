import { useState, useEffect, useRef } from "react";
import Appraisal from "./Appraisal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";

export default function AppraisalList(props) {
  const appraisalListRef = useRef(null);
  const [editing, setEditing] = useState(false);
  const edit = () => {
    setEditing(!editing);
  };

  const smoothScrollToTop = (element) => {
    const startLocation = element.scrollTop;
    const targetLocation = 0;
    const duration = 1000;
    let startTime = null;

    const animation = (currentTime) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const progress = timeElapsed / duration;
      const easeInOutQuad = (t) =>
        t < 0.5 ? 2 * t * t : 1 - (-2 * t + 2) * (t - 1);
      const newLocation =
        startLocation +
        (targetLocation - startLocation) * easeInOutQuad(progress);
      element.scrollTop = newLocation;
      if (progress < 1) {
        window.requestAnimationFrame(animation);
      }
    };

    window.requestAnimationFrame(animation);
  };

  const scrollToTop = () => {
    smoothScrollToTop(appraisalListRef.current);
  };

  useEffect(() => {
    scrollToTop();
  }, [props.page]);

  return (
    <>
      <div
        className="appraisal_list"
        ref={appraisalListRef}
        style={{ paddingBottom: "10px" }}
      >
        {props.appraisal_data &&
          props.appraisal_data.map((e, index) => (
            <Appraisal
              key={index}
              data={e}
              index={index}
              edit={edit}
              refreshData={props.refreshData}
            />
          ))}
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              backgroundColor: "#fff",
              paddingY: 3,
              marginTop: 3,
              marginBottom: 5,
            }}
          >
            <Stack spacing={2}>
              <Pagination
                count={props.count}
                page={props.page}
                onChange={props.handleChange}
              />
            </Stack>
          </Box>
        </Box>
      </div>
    </>
  );
}
