import { BASE_URL, BASE_URL_2 } from "../config/config";
import { httpGet, httpPost } from "../config/httpService";



const URL = `${BASE_URL_2}/buyer`;
const URL2 = `${BASE_URL_2}`
// Get all Employees
export const RegisterBuyer = async (registerData) => {
   try {
      const response = await httpPost(`${URL}/registration`,registerData );
      const data = response?.data;
      return data;
   } catch (error) {
      throw error?.response?.data;
   }
};

export const CheckEmailValidation = async (email) => {
   try {
      const response = await httpGet(`${URL}/registration/isEmailExist?email=${email}`);
      const data = response?.data;
      return data;
   } catch (error) {
      throw error?.response?.data;
   }
};



