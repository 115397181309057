import React from 'react'
import HistoryTable from '../../components/edit-history'

const EditHistoryLayout = () => {
  return (
    <div>
      <HistoryTable />
    </div>
  )
}

export default EditHistoryLayout
