import React, { useState, useEffect } from "react";
import { PlusOutlined, MinusOutlined, CheckOutlined } from "@ant-design/icons";
import { Input, Button } from "antd";
import { setCustomErrorToast } from "../../utils/notifications";
import { useMatchingCondtionsStore } from "../../stores/matchingCondtions.store";

export const MultipleInputList = (props) => {
  const [inputs, setInputs] = useState([{ key: "", value: "" }]);
  const [initialized, setInitialized] = useState(false);
  const setEmailList = useMatchingCondtionsStore((state) => state.setEmailList);
  const emailList = useMatchingCondtionsStore((state) => state.emailList);

  useEffect(() => {
    if (!initialized && emailList && emailList?.length > 0) {
      setInputs(emailList?.map((email) => ({ key: "", value: email.email })));
      setInitialized(true); // Mark as initialized to prevent re-running
    } else if (inputs?.length === 0) {
      setInputs([{ key: "", value: "" }]);
    }
  }, [emailList, initialized, inputs.length]);

  useEffect(() => {
    const results = inputs
      .filter((input) => input?.value && validateEmail(input?.value))
      .map((input, i) => ({
        order: i + 1,
        email: input.value,
      }));
    setEmailList(results);
  }, [inputs]);

  const handleAddInput = () => {
    const lastInput = inputs[inputs?.length - 1];
    if (lastInput?.value && !validateEmail(lastInput?.value)) {
      setCustomErrorToast("無効なメールアドレスです。");
      return;
    }

    const newInputs = [...inputs, { key: "", value: "" }];
    setInputs(newInputs);
  };

  const handleInputChange = (index, event) => {
    const newInputs = inputs?.map((input, i) => {
      if (i === index) {
        return { ...input, value: event?.target?.value };
      }
      return input;
    });
    setInputs(newInputs);
  };

  const handleRemoveInput = (index) => {
    const newInputs = inputs?.filter((_, i) => i !== index);
    setInputs(newInputs);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <div className="w-full h-full">
      {inputs?.map((input, index) => (
        <>
          <div key={index} className="flex items-center gap-4">
            <Input
              value={input?.value}
              onChange={(e) => handleInputChange(index, e)}
              className="w-full bg-[#fff9c5] py-2 my-2"
              required
            />
            <Button
              icon={<MinusOutlined />}
              onClick={() => handleRemoveInput(index)}
            />
            {index === inputs?.length - 1 && (
              <Button icon={<PlusOutlined />} onClick={handleAddInput} />
            )}
          </div>
          <h1 className="text-red-500">
            {input?.value &&
              input?.value?.length > 0 &&
              !validateEmail(input?.value) &&
              "無効なメールアドレスです"}
          </h1>
        </>
      ))}
      <Button
        className="hidden"
        type="primary"
        icon={<CheckOutlined />}
        onClick={() =>
          setEmailList(inputs?.map((input) => ({ email: input.value })))
        }
      >
        Submit Emails
      </Button>
    </div>
  );
};
