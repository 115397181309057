import { create } from "zustand";

const initialState = {
  selectors: {
    selector1: false,
    selector2: false,
    selector3: false,
    option1: false,
    option2: false,
  },
};

export const useSelectedTypeStore = create((set) => ({
  ...initialState,
  setSelectors: (newSelectors) =>
    set((state) => ({
      selectors: {
        ...state.selectors,
        ...newSelectors,
      },
    })),
}));
