
import { useNavigate } from 'react-router-dom';
import Button from '../../components/button';
import { useBackend } from '../../hooks/useBackend';

export default function Logout(props) {
    const api = useBackend();
    const navigate = useNavigate();
    const logout = () => {
        localStorage.clear()
        api.logout().then(e => navigate("/"));
    }
    return (
    <div className='space_right_narrow'>
        <Button label="ログアウト" color={"sub"} on onClick={logout} />
    </div>
    )
}