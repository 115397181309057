import { useMemo, useRef, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Button } from "@mui/material";
import { ModalComponent } from "../../components";
import AssessmentRequestDetailsLayout from "../../layouts/assessment-request-details";
import moment from "moment";

export default function AssessmentRequestListTable(props) {
  const [isOpen, setIsOpen] = useState(false);
  const selectedAppraisal = useRef(null);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const columns = useMemo(
    () => [
      {
        accessorKey: "actionButtons",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            アクション
          </p>
        ),
        Cell: ({ row }) => (
          <div className="ctn-wrapper">
            <Button
              variant="contained"
              onClick={() => {
                selectedAppraisal.current = row?.original;
                handleOpen();
              }}
            >
              詳細
            </Button>
          </div>
        ),
      },
      {
        accessorKey: "date_of_application",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>申請日</p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row?.original?.supplement ? moment(row?.original?.supplement.requestYMD).format('L')  : "_"}
          </span>
        ),
      },
      {
        accessorKey: "date_of_sending",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>送客日</p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row?.original?.assessed?.emailSendTime
              ? moment(row?.original?.assessed?.emailSendTime).format('L')
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "media",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>媒体</p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <span>{row?.original?.type ? row?.original?.type : "_"}</span>
        ),
      },
      {
        accessorKey: "name",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>氏名</p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row?.original?.customer?.name ? row?.original?.customer.name : "_"}
          </span>
        ),
      },
      {
        accessorKey: "address",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>住所</p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row?.original?.customer?.address
              ? row?.original?.customer.address
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "telephone_number",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            電話番号
          </p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row?.original?.customer?.phone?.content
              ? row?.original?.customer.phone.content
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "vehicle_type",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>車種</p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row?.original?.car?.car_type ? row?.original?.car.car_type : "_"}
          </span>
        ),
      },
      {
        accessorKey: "model_grade",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            グレード
          </p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <span>{row?.original?.car?.grade ? row?.original?.car.grade : "_"}</span>
        ),
      },
      {
        accessorKey: "year",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>年式</p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row?.original?.car?.car_model_year
              ? row?.original?.car.car_model_year
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "distance",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>距離</p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row?.original?.car?.car_traveled_distance
              ? row?.original?.car.car_traveled_distance
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "body_color",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            ボディカラー
          </p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row?.original?.car?.body_color ? row?.original?.car.body_color : "_"}
          </span>
        ),
      },
      {
        accessorKey: "date_of_sale",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            売却時期
          </p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row?.original?.car?.desire_date ? row?.original?.car.desire_date : "_"}
          </span>
        ),
      },
      {
        accessorKey: "loan_balance",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            ローン残債
          </p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <span>{row?.original?.car.loan ? row?.original?.car.loan : "_"}</span>
        ),
      },
      {
        accessorKey: "potential_appraisal_date",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            査定候補日
          </p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row?.original?.aDates?.content?.content
              ? moment(row?.original?.aDates.content.content).format('L') 
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "parameters",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            パラメータ
          </p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row?.original?.supplement?.param
              ? row?.original?.supplement.param
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "remarks",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>備考</p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row?.original?.supplement?.note ? row?.original?.supplement.note : "_"}
          </span>
        ),
      },
      {
        accessorKey: "store_sent_customers_1",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            送客店名1
          </p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row?.original?.shops?.shops[0]
              ? row?.original?.shops.shops[0]?.name
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "store_sent_customers_2",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            送客店名2
          </p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row?.original?.shops?.shops[1]
              ? row?.original?.shops.shops[1]?.name
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "store_sent_customers_3",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            送客店名3
          </p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <span>
            {row?.original?.shops?.shops[2]
              ? row?.original?.shops.shops[2]?.name
              : "_"}
          </span>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: props.data,
    getSubRows: (row) => row.branches,
    enableExpanding: true,
    enableRowSelection: false,
    enableColumnOrdering: false,
    enableGlobalFilter: false,
    enableTopToolbar: false,
    enableSorting: false,
    enableFilters: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    localization: {
      rowsPerPage: 'ページあたりの行数',
      of: 'の'
    },
  });

  return (
    <>
      <div className="material-react-table-body">
        <MaterialReactTable table={table} />
      </div>
      <ModalComponent isOpen={isOpen} handleClose={handleClose}>
        <AssessmentRequestDetailsLayout
          handleClose={handleClose}
          data={selectedAppraisal.current}
        />
      </ModalComponent>
    </>
  );
}
