import { useEffect, useState } from "react";
import { useAppStore } from "../stores/app.store";
import { getShopById } from "../app/services/shop.service";

export const useFetchShopByIdHook = (id) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [shop, setShop] = useState();
  const { editRegisterFormStatus, editRegisterId } = useAppStore();

  const fetchShopById = async () => {
    try {
      setLoading(true);
      const response = await getShopById(id || editRegisterId);
      setShop(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (id || editRegisterId) {
      fetchShopById();
    }
  }, [id, editRegisterId]);

  return { shop, error, loading };
};
