export const isEmptyOrNullObject = (obj) => {
    if (obj === null || typeof obj !== 'object') {
      return true;
    }
    
    return Object.keys(obj).every(key => obj[key] === null);
  };
  export function hasNonNullValues(obj) {
    for (let key in obj) {
        if (obj[key] !== null) {
            return true;
        }
    }
    return false;
}

export function getValuesAsStringArray(obj) {
  return Object.values(obj)
    .filter(value => value !== null)  // Remove null values
    .map(value => value.toString());   // Convert remaining values to string
}

