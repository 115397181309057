export const generateCurrentYearMonth = () => {

    const currentTime = new Date();

    const response = {
        month : currentTime.getMonth() + 1,
        year: currentTime.getFullYear()
    }

    return response;
}