import { useNavigate } from "react-router-dom";
import { useAuthUserContext } from "./AuthContext";

export const AuthGuard = (props) => {
    const auth = useAuthUserContext();
    const navigate = useNavigate()
  
    if (auth && auth.user) 
    return <>{props.component}</>;
    auth.am_i_logged_in()
    .catch(e => {
      navigate('/console/login')
    })
  }