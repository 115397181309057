import React, { useState, useEffect, useRef, Fragment } from "react";
import { Checkbox } from "antd";
import { useDisabledStore } from "../../stores/disable.store";
import { hasNonNullValues } from "../adminPortalComponents/matchingCondition/functions/checkNullValues";
import { useAppStore } from "../../stores/app.store";
import { useSelectAllStore } from "../../stores/selectAllStore";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { useSelectedTypeStore } from "../../stores/selectors.store";

// Constants for loading state
const LOADING = 1;
const LOADED = 2;
let itemStatusMap = {};

// Helper functions
const isItemLoaded = (index) => !!itemStatusMap[index];
const loadMoreItems = (startIndex, stopIndex) => {
  for (let index = startIndex; index <= stopIndex; index++) {
    itemStatusMap[index] = LOADING;
  }
  return new Promise((resolve) =>
    setTimeout(() => {
      for (let index = startIndex; index <= stopIndex; index++) {
        itemStatusMap[index] = LOADED;
      }
      resolve();
    }, 1000)
  );
};

export const NestedScrollSelector = (props) => {
  const [selectAll, setSelectAll] = useState(false);
  const [nestedSelectAll, setNestedSelectAll] = useState({});
  const { disabled, setDisabled } = useDisabledStore();
  const { setSelecteAllAction } = useSelectAllStore();
  const { selectors, setSelectors } = useSelectedTypeStore();
  const [disableAll, setDisableAll] = useState(false);
  const [checkedValues, setChekedValues] = useState({});

  const [expandedItems, setExpandedItems] = useState({});

  // Refs to store checked values and initial values
  const checkedValuesRef = useRef({});
  const initialCheckedValuesRef = useRef({});
  const prevSelectAllRef = useRef(false);

  const handleUncheckAll = () => {
    setSelectAll(false);
    setDisableAll(false)
    // Uncheck all items
    const updatedCheckedValues = {};
    props.data.forEach((item) => {
      updatedCheckedValues[item.key] = null;
    });

    checkedValuesRef.current = updatedCheckedValues;
    props.setCheckedValues(updatedCheckedValues);
    handleUpdate(false);
  };

  const handleToggleExpand = (key) => {
    setExpandedItems((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };
useEffect(() => {
    // Initialize initial values
    initialCheckedValuesRef.current = props.checkedValues || {};
    checkedValuesRef.current = { ...initialCheckedValuesRef.current };

    if (Object.keys(checkedValues).length == 0) {
      setChekedValues(checkedValuesRef.current);
    }
    // Update select-all state and nested select-all state
    const allChecked = props.data.every((item) =>
      item.children
        ? item.children.every(
            (child) =>
              checkedValuesRef.current[child.key] !== null &&
              checkedValuesRef.current[child.key] !== undefined
          )
        : checkedValuesRef.current[item.key] !== null &&
          checkedValuesRef.current[item.key] !== undefined
    );
    if (allChecked !== selectAll) {
      setSelectAll(allChecked);
      setDisableAll(true);
      prevSelectAllRef.current = allChecked;
    }
    const newSelectAllAction = checkAllSelectors(allChecked, props.modalType);
    if (Object.keys(newSelectAllAction).length > 0) {
      setSelecteAllAction(newSelectAllAction);
    }

    // Update nested select-all state
    const updatedNestedSelectAll = {};
    props.data.forEach((item) => {
      if (item.children) {
        const allChildrenChecked = item.children.every(
          (child) =>
            checkedValuesRef.current[child.key] !== null &&
            checkedValuesRef.current[child.key] !== undefined
        );
        const someChildrenChecked = item.children.some(
          (child) =>
            checkedValuesRef.current[child.key] !== null &&
            checkedValuesRef.current[child.key] !== undefined
        );
        updatedNestedSelectAll[item.key] = {
          all: allChildrenChecked,
          some: someChildrenChecked,
        };
      }
    });
    setNestedSelectAll(updatedNestedSelectAll);
  }, [props.checkedValues,checkedValues, props.data, props.modalType,selectAll,disableAll]);


  const checkAllSelectors = (allChecked, type) => {
    const newSelectAllAction = {};
    if (prevSelectAllRef.current !== allChecked) {
      switch (type) {
        case "okMakes":
          newSelectAllAction.okMakes = allChecked;

          break;
        case "okCarTypes":
          newSelectAllAction.okCarTypes = allChecked;

          break;
        case "okBodyTypes":
          newSelectAllAction.okBodyTypes = allChecked;

          break;
        case "ngCarTypes":
          newSelectAllAction.ngCarTypes = allChecked;

          break;
        case "ngBodyTypes":
          newSelectAllAction.ngBodyTypes = allChecked;
          break;
        case "ngMakes":
          newSelectAllAction.ngMakes = allChecked;
          break;
        default:
          break;
      }
      prevSelectAllRef.current = allChecked;
    }
    return newSelectAllAction;
  };

  useEffect(() => {
    if (Object.keys(checkedValues).length > 0) {
      handleUpdate();
    }
  }, [checkedValues]);

  const handleUpdate = () => {
    if (
      props.type === "selector2" &&
      hasNonNullValues(checkedValuesRef.current)
    ) {
      setSelectors({
        ...selectors,
        selector2: true,
      });
    } else if (
      !hasNonNullValues(checkedValuesRef.current) &&
      props.type === "selector2"
    ) {
      setSelectors({
        ...selectors,
        selector2: false,
      });
    }
    const newSelectAllAction = checkAllSelectors(false, props.modalType);
    if (Object.keys(newSelectAllAction).length > 0) {
      setSelecteAllAction(newSelectAllAction);
    }
  };

  const handleCheckboxChange = (item, checked) => {
    const updatedCheckedValues = {
      ...checkedValuesRef.current,
      [item.key]: checked ? item.value : null,
    };
    checkedValuesRef.current = updatedCheckedValues;
    props.setCheckedValues(updatedCheckedValues);
    handleUpdate();
  };

  const handleNestedCheckboxChange = (parentItem, child, checked) => {
    const updatedCheckedValues = {
      ...checkedValuesRef.current,
      [child.key]: checked ? child.value : null,
    };
    checkedValuesRef.current = updatedCheckedValues;
    props.setCheckedValues(updatedCheckedValues);
    handleUpdate();
  };

  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);
    const updatedCheckedValues = {};
    props.data.forEach((item) => {
      if (item.children) {
        item.children.forEach((child) => {
          updatedCheckedValues[child.key] = checked ? child.value : null;
        });
      } else {
        updatedCheckedValues[item.key] = checked ? item.value : null;
      }
    });
    checkedValuesRef.current = updatedCheckedValues;
    props.setCheckedValues(updatedCheckedValues);
    setDisableAll(!disableAll);
    handleUpdate();
  };

  const handleNestedSelectAllChange = (parentItem, e) => {
    const checked = e.target.checked;
    setNestedSelectAll((prev) => ({
      ...prev,
      [parentItem.key]: {
        all: checked,
        some: checked,
      },
    }));
    const updatedCheckedValues = {};
    parentItem.children.forEach((child) => {
      updatedCheckedValues[child.key] = checked ? child.value : null;
    });
    checkedValuesRef.current = {
      ...checkedValuesRef.current,
      ...updatedCheckedValues,
    };
    props.setCheckedValues(updatedCheckedValues);
    handleUpdate();
  };

  const Row = ({ index, style }) => {
    const item = props.data[index];
    let content;

    if (itemStatusMap[index] === LOADED) {
      content = (
        <div key={item.key} style={style}>
          <Checkbox
            checked={
              item.children
                ? nestedSelectAll[item.key]?.all || false
                : checkedValuesRef.current[item.key] !== null &&
                  checkedValuesRef.current[item.key] !== undefined
            }
            indeterminate={item.children && nestedSelectAll[item.key]?.some}
            onChange={(e) =>
              item.children
                ? handleNestedSelectAllChange(item, e)
                : handleCheckboxChange(item, e.target.checked)
            }
            disabled={props.disabled}
          >
            {item.label}
          </Checkbox>
          {item.children && (
            <div className="pl-4">
              {item.children.map((child) => (
                <div key={child.key} className="w-full">
                  <Checkbox
                    checked={
                      checkedValuesRef.current[child.key] !== null &&
                      checkedValuesRef.current[child.key] !== undefined
                    }
                    onChange={(e) =>
                      handleNestedCheckboxChange(item, child, e.target.checked)
                    }
                    disabled={props.disabled}
                  >
                    {child.label}
                  </Checkbox>
                </div>
              ))}
            </div>
          )}
        </div>
      );
    } else {
      content = <div style={style}>Loading...</div>;
    }

    return content;
  };

  const renderChildren = (children) => (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={children.length}
      loadMoreItems={loadMoreItems}
    >
      {({ onItemsRendered, ref }) => (
        <List
          height={200}
          itemCount={children.length}
          itemSize={18}
          onItemsRendered={onItemsRendered}
          ref={ref}
          width="100%"
        >
          {({ index, style }) => {
            const child = children[index];
            return (
              <div key={child.key} style={style} className="w-full">
                <Checkbox
                  checked={
                    checkedValuesRef.current[child.key] !== null &&
                    checkedValuesRef.current[child.key] !== undefined
                  }
                  onChange={(e) =>
                    handleNestedCheckboxChange(null, child, e.target.checked)
                  }
                  disabled={props.disabled || disableAll}

                >
                  {child.label}
                </Checkbox>
              </div>
            );
          }}
        </List>
      )}
    </InfiniteLoader>
  );

  return (
    <>
      <h1
        className=" w-full text-left cursor-pointer "
        onClick={handleUncheckAll}
      >
        選択解除
      </h1>
      <div className="w-full border-[1px] border-blue-400 my-2">
        <div className="w-full p-4">
          <Checkbox
            checked={selectAll}
            onChange={handleSelectAllChange}
            disabled={props.disabled}
          >
            すべて選択
          </Checkbox>
        </div>
        <div className="w-full p-4 mb-2 h-[30rem] overflow-y-auto">
          {props.data.map((item) => (
            <div key={item.key} className="w-full">
              <Checkbox
                className={`${item.children ? "hide-checkbox" : ""}`} // Apply the class conditionally
                checked={
                  item.children
                    ? expandedItems[item.key]?.all || false
                    : checkedValuesRef.current[item.key] !== null &&
                      checkedValuesRef.current[item.key] !== undefined
                }
                indeterminate={item.children && expandedItems[item.key]?.some}
                onChange={(e) =>
                  item.children
                    ? handleToggleExpand(item.key)
                    : handleCheckboxChange(item, e.target.checked)
                }
                disabled={props.disabled || disableAll}
              >
                {item.label}
              </Checkbox>
              {expandedItems[item.key] && item.children && (
                <div className="pl-4">{renderChildren(item.children)}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
