
import RAssessmentDatesSection from "../assessment-request-details/reject-list/assessment-dates-section";
import RBasicInformationSection from "../assessment-request-details/reject-list/basic-information-section";
import RCarAccessoriesSection from "../assessment-request-details/reject-list/car-accessories-section";
import RExteriorConditionSection from "../assessment-request-details/reject-list/exterior-condition-sectiion";
import RInteriorConditionSection from "../assessment-request-details/reject-list/interior-condition-section";
import RStatusSection from "../assessment-request-details/reject-list/status-section";
import RVehicleSection from "../assessment-request-details/reject-list/vehicle-section";


function AssessmentDetailsLayout(props) {
    console.log("data new",props);
    return (
      <div style={{ padding: "5%" }}>
        <RStatusSection data={props.data}/>
        <RBasicInformationSection data={props.data} />
        <RAssessmentDatesSection data={props.data} />
        <RVehicleSection data={props.data} />
        <RExteriorConditionSection data={props.data} />
        <RInteriorConditionSection data={props.data} />
        <RCarAccessoriesSection data={props.data} />
      </div>
    );
  }
  
  export default AssessmentDetailsLayout;