export const bodyTypes = [
  {
    body_type: "セダン",
  },
  {
    body_type: "コンパクトカー",
  },
  {
    body_type: "SUV・クロカン",
  },
  {
    body_type: "ピックアップトラック",
  },
  {
    body_type: "軽自動車",
  },
  {
    body_type: "ステーションワゴン",
  },
  {
    body_type: "オープンカー",
  },
  {
    body_type: "ハッチバック",
  },
  {
    body_type: "ミニバン・ワンボックス",
  },
  {
    body_type: "クーペ",
  },
  {
    body_type: "トラック・バス",
  },
  {
    body_type: "商用車・バン",
  },
  {
    body_type: "福祉車両",
  },
  {
    body_type: "キャンピングカー",
  },
];



