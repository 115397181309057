import Button from "../../components/button";
import { useState } from "react";
import Input from "../../components/input";
import TextArea from "../../components/textarea";
import option from "../../data/car.json";
import DropDown from "../../components/dropdown";
import Radio from "../../components/choise";
import choise from "../../data/choises.json";
import DateTime from "../../components/datetime";
import { useBackend } from "../../hooks/useBackend";

export default function MatchConfirm(props) {
  const [clicked, setClicked] = useState({ on: false, off: true });
  const [data, setData] = useState(props.data);
  const api = useBackend();
  const done = async (e) => {
    try {
      await api.done(data?.appraisalid?.content);
      if (props.refreshData) {
        await props.refreshData();
      }
      if (props.onClose) props.onClose();
    } catch (error) {
      console.error("Error in done:", error);
    }
  };
  const doneDraft = async (e) => {
    try {
      await api.draft_done(data?.appraisalid?.content);
      if (props.refreshData) {
        await props.refreshData();
      }
      if (props.onClose) props.onClose();
    } catch (error) {
      console.error("Error in doneDraft:", error);
    }
  };

  const onComplete = (e) => {
    if (props.onClose) props.onClose();
  };
  const close = (e) => {
    if (!props.onClose) return;
    if (e.target.closest(".mordal_match") === null) {
      props.onClose();
    }
  };
  const set = (d) => setData((pre) => ({ ...pre, ...d }));

  return (
    <div className={`mordal_background`} onClick={close}>
      <div className="mordal_match flex-center">
        <h2>買取店にメールを送信します！</h2>
        <div className="mordal_match_dropdown_container">
          この操作は取り消し不可です。
        </div>
        <div className="button-flex">
          <div className="mordal_match_dropdown">
            <Button
              label="キャンセル"
              color={"gray"}
              on
              onClick={props.onClose}
            ></Button>
          </div>
          <div className="mordal_match_dropdown">
            <Button label="決定" color={"main"} on onClick={done}></Button>
          </div>
        </div>
        <div className="button-flex">
          <div className="mordal_match_dropdown">
            <Button
              label="テスト送信"
              color={"dark_blue"}
              on
              onClick={doneDraft}
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
}
