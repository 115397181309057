import React from "react";
import {
  Select as AntSelect,
  SelectProps as AntSelectProps,
  ConfigProvider,
} from "antd";

export const Select = ({
  allowClear = false,
  autoClearSearchValue = true,
  listHeight = 256,
  placement = "bottomLeft",
  type = "outlined",
  fullWidth = true,
  options,
  disable,
  ...props
}) => {
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Select: {
              optionSelectedBg: "var(--color-primary-light)",
              colorBgContainer: "#fff9c5"
            },
          },
        }}
      >
    <div className=" w-full flex items-center gap-2">
          {props.label && (
            <label
              htmlFor={props.id}
              className="text-sm block font-semibold py-4 px-2 m-0  !bg-[#587c94] border-b-[1px] border-white text-white min-w-[7rem]"
            >
              {props.label}
            </label>
          )}
          <AntSelect
            allowClear={allowClear}
            options={options}
            autoClearSearchValue={autoClearSearchValue}
            filterOption={filterOption}
            listHeight={listHeight}
            placement={placement}
            variant={type}
            className={`${fullWidth ? " w-full" : ""} no-outline ${
              props.className
            }`}
            {...props}
            disabled={disable}
            onChange={props.onChange}
            key={props.key}
            aria-required={props.required}
            
          />
          
        </div>
        {props.error && <h3 className="pl-28 text-red-600 ">Can not empty</h3>}
      </ConfigProvider>
    </>
  );
};
