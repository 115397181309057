import Plus from "./icon_plus";
import { useState, useEffect } from "react";
import CloudSyncIcon from "@mui/icons-material/CloudSync";

export default function ToggleButton(props) {
  const [active, setActive] = useState(props.has_state ?? false);
  // Update active state when has_state prop changes
  useEffect(() => {
    setActive(props.has_state ?? false);
  }, [props?.has_state]);

  const on_click = (e) => {
    if (props.onClick) props.onClick(e);
  };

  const iconV2 = () => {
    if (props.plus) return <Plus />;
    if (props.cloud) return <CloudSyncIcon style={{ marginRight: "4px" }} />;
  };

  return (
    <>
      <div className="button_wrapper">
        <button
          onClick={on_click}
          disabled={props.disabled}
          className={`font_button_label ${props.color} ${
            active ? "on" : "off"
          } ${
            props.xs
              ? "xs"
              : props.small
              ? "small"
              : props.middle
              ? "middle"
              : "normal"
          } ${props.custom} `}
        >
          <span className="button-label">
            <div className="icon-label-wrapper" sx={{ display: "flex" }}>
              {iconV2()} {props.label}
            </div>
          </span>
        </button>
      </div>
    </>
  );
}
