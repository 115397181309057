import AntInput from "antd/es/input";

export const Input = (props) => {
  return (
    <>
      <div className=" w-full flex items-center gap-2">
        {props.label && (
          <label
            htmlFor={props.id}
            className={`text-sm block font-semibold py-4 px-2  !bg-[#587c94] border-b-[1px] border-white text-white min-w-[7rem] ${props.labelStlye}`}
          >
            {props.label}
          </label>
        )}
        <AntInput
          name={props.name}
          className={`${props.className}`}
          size={props.size}
          defaultValue={props.defaultValue}
          disabled={props.disabled}
          allowClear={props.allowClear}
          id={props.id}
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.onChange}
          onPressEnter={props.onPressEnter}
          required={props.required}
        />
      </div>
    </>
  );
};
