import React from "react";
import { Button, Stack } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";

export default function NavigationBilling({ mainId }) {
  const navigate = useNavigate();
  const { state } = useLocation();


  const navigateToPreviousPage = () => {
    if (mainId) {
      navigate(`/console/billing/${mainId}`);
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      {state && (state.previousPage || state.previousPageSubLevel) && (
        <div className="ctn-wrapper" style={{ marginBottom: "20px" }}>
          <Button variant="contained" onClick={navigateToPreviousPage}>
            <ArrowBackIcon />
          </Button>
        </div>
      )}
    </Stack>
  );
}
