import { useState } from 'react';
import Button from '../../components/button';
import Input from '../../components/input';
import Logo from '../../components/Logo'
import { useBackend } from '../../hooks/useBackend';
import { useNavigate } from 'react-router-dom';
import { useAuthUserContext } from './AuthContext';
import Text from '../../components/text';
import jwtDecode from 'jwt-decode';

export default function LoginView() {
  const api = useBackend();
  const [show_incorrect_pass_or_id_msg, setShowIncorrectPassOrIdMsg] = useState(false)
  const [showAccessDeniedMsg, setShowAccessDeniedMsg] = useState(false)
  const [unknown, setUnknown] = useState(false)
  const [loginDetails, setLoginDetails] = useState({
    username: "",
    password: ""
  });
  const navigate = useNavigate();
  const auth = useAuthUserContext();

 const onChangeHandlerUsername = (data) => {
    setLoginDetails((prevState)=>({
      ...prevState,
      username: data.username
    }))
 }

 const onChangeHandlerPassword = (data) => {
  setLoginDetails((prevState)=>({
    ...prevState,
    password: data.password
  }))
}

  const login = async () => {
    try {
      const response = await api.login(loginDetails);
      
      const token = response.data.token;
      const decodedToken = jwtDecode(token);
      
      if (decodedToken.roles === "ROLE_ADMIN") {
        localStorage.setItem("token", token);
        var user = await auth.am_i_logged_in();
        if (user && user.data) {
          navigate("/console/appraisals");
        }
      } else {
        setShowAccessDeniedMsg(true);
        setShowIncorrectPassOrIdMsg(false);
        setUnknown(false)
        navigate("/console/login");
      }
    } catch (e) {
      if(e.response.status==401){
        setShowIncorrectPassOrIdMsg(true);
        setShowAccessDeniedMsg(false);
      } else {
        setUnknown(true)
        setShowAccessDeniedMsg(false);
      }
      console.log(e);
      navigate("/console/login");
    }
  };
 
  return (
    <div className="root">
        <div className="login_form_container">
            <Logo center/>
          <div className='space_side_large space_vertical_middle'>
          {show_incorrect_pass_or_id_msg && <div>IDまたはパスワードが違います。</div>}
          {showAccessDeniedMsg && <div>アクセスが拒否されました: ユーザーは管理者ではありません</div>}
          {unknown && <div>不明なエラー</div>}
          </div>
          <div className='space_side_large space_vertical_middle'>
            <Input label="ID" id="username" name="username" onChange={onChangeHandlerUsername}/>
          </div>
          <div className='space_side_large space_vertical_middle'>
            <Input label="パスワード" id="password"  name="password" password onChange={onChangeHandlerPassword}/>
          </div>
          <div className='space_large'>
            <Button label="ログイン" color={"main"} main on onClick={login}></Button>
          </div>
        </div>
    </div>
  );
}