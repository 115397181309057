import React, { useState } from "react";
import {
  Box,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Button from "../../components/button";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import Edit from "./Edit";
import { useBackend } from "../../hooks/useBackend";

const FilterSectionAppraisals = (props) => {
  const [clearStatus, setClearStatus] = useState(null);
  const api = useBackend();
  const [addAppraisals, setAddAppraisals] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(false);

  const handleDropDownChange = (event) => {
    setDropdownValue(event.target.value);
    props.setAppraisalsQueryParams &&
      props.setAppraisalsQueryParams((prevState) => ({
        ...prevState,
        isfilterFromEmailSendTime: event.target.value,
      }));
  };

  const closeAddAppraisals = () => {
    setAddAppraisals(!addAppraisals);
  };

  const openAddAppraisals = () => {
    setAddAppraisals(!addAppraisals);
  };

  const generate_appraisal_list_report = async () => {
    setDownloading(true);

    const csvUrl = await props.onClickCSV();
    if (csvUrl) {
      setTimeout(() => {
        setDownloading(false);
        // Create a hidden link to download the file
        const link = document.createElement("a");
        link.href = `https://assessed-appraisals-csv.s3.ap-northeast-1.amazonaws.com/filtered-data.csv`;
        link.download = "data.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }, 1000);
    }
  };

  const onClickFilterHandler = (name) => {
    props.setAppraisalsQueryParams &&
      props.setAppraisalsQueryParams((prevState) => ({
        ...prevState,
        [name]: !prevState[name],
      }));
  };

  const onChangeKeyWordHandler = (e) => {
    props.setAppraisalsQueryParams &&
      props.setAppraisalsQueryParams((prevState) => ({
        ...prevState,
        kw: e.target.value,
      }));
  };

  const onChangePhoneNumber = (e) => {
    props.setAppraisalsQueryParams &&
      props.setAppraisalsQueryParams((prevState) => ({
        ...prevState,
        phone_no: e.target.value,
      }));
  };

  const onChangeDate = (data, name) => {
    const formattedDate = moment(
      data.$d,
      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
    ).format("YYYY/MM/DD");

    props.setAppraisalsQueryParams &&
      props.setAppraisalsQueryParams((prevState) => ({
        ...prevState,
        [name]: formattedDate,
      }));
  };

  const onClickSearch = () => {
    props.onClickSearch();
  };

  const onClickClear = () => {
    setClearStatus(Math.random().toString());
    setDropdownValue(false);
    const appraisalsClearParams = {
      not_compatible: false,
      processing: false,
      appointment: false,
      closed: false,
      unsent: false,
      submit_an_application: false,
      recognition_applied: false,
      recognition_not_accepted: false,
      cancelled: false,
      seo: false,
      paid: false,
      paid2: false,
      paid3: false,
      paid4: false,
      phone: false,
      phone_no: "",
      isTestData: false,
      kw: "",
      offset: 0,
      limit: 20,
      to: null,
      from: null,
      isfilterFromEmailSendTime: false,
    };

    props.setAppraisalsQueryParams &&
      props.setAppraisalsQueryParams((prevState) => ({
        ...prevState,
        ...appraisalsClearParams,
      }));
    props.onClickClear && props.onClickClear(appraisalsClearParams);
  };

  const onClickTestData = () => {
    props.setAppraisalsQueryParams &&
      props.setAppraisalsQueryParams((prevState) => ({
        ...prevState,
        isTestData: !prevState.isTestData,
      }));
  };

  return (
    <div>
      <Box
        maxWidth={"100vw"}
        sx={{
          width: "100%",
          paddingTop: "5px",
          paddingBottom: "5px",
          alignContent: "center",
          marginBottom: "7px",
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="column" spacing={1}>
            <Stack direction={{ sm: "column", md: "row" }} spacing={1} sx={{}}>
              <Button
                small
                has_state
                clearStatus={clearStatus}
                label="未対応"
                color={"red"}
                onClick={() => {
                  onClickFilterHandler("not_compatible");
                }}
              />
              <Button
                small
                has_state
                label="対応中"
                color={"yellow"}
                clearStatus={clearStatus}
                onClick={() => {
                  onClickFilterHandler("processing");
                }}
              />
              <Button
                small
                has_state
                label="アポ確定"
                color={"blue"}
                clearStatus={clearStatus}
                onClick={() => {
                  onClickFilterHandler("appointment");
                }}
              />
              <Button
                small
                has_state
                label="送客済"
                color={"gray"}
                clearStatus={clearStatus}
                onClick={() => {
                  onClickFilterHandler("closed");
                }}
              />
              <Button
                small
                has_state
                label="未送客"
                color={"purple"}
                clearStatus={clearStatus}
                onClick={() => {
                  onClickFilterHandler("unsent");
                }}
              />
              <Button
              has_state
                label="キャンセル済み"
                color={"green"}
                clearStatus={clearStatus}
                onClick={() => {
                  onClickFilterHandler("cancelled");
                }}
              />

              {/* <Button
                middle
                has_state
                label="却下申請中"
                color={"green"}
                clearStatus={clearStatus}
                onClick={() => {
                  onClickFilterHandler("submit_an_application");
                }}
              />
              <Button
                middle
                has_state
                label="却下申請承認"
                color={"green"}
                clearStatus={clearStatus}
                onClick={() => {
                  onClickFilterHandler("recognition_applied");
                }}
              />
              <Button
                middle
                has_state
                label="却下申請棄却"
                color={"green"}
                clearStatus={clearStatus}
                onClick={() => {
                  onClickFilterHandler("recognition_not_accepted");
                }}
              /> */}
            </Stack>
            <Stack direction={{ sm: "column", md: "row" }} spacing={1} sx={{}}>
              <Button
                xs
                has_state
                label="SEO"
                color={"main"}
                clearStatus={clearStatus}
                onClick={() => {
                  onClickFilterHandler("seo");
                }}
              />
              <Button
                xs
                has_state
                label="Paid"
                color={"main"}
                clearStatus={clearStatus}
                onClick={() => {
                  onClickFilterHandler("paid");
                }}
              />
              <Button
                small2
                has_state
                label="Paid2"
                color={"main"}
                clearStatus={clearStatus}
                onClick={() => {
                  onClickFilterHandler("paid2");
                }}
              />
              <Button
                small2
                has_state
                label="Paid3"
                color={"main"}
                clearStatus={clearStatus}
                onClick={() => {
                  onClickFilterHandler("paid3");
                }}
              />
              <Button
                small2
                has_state
                label="Paid4"
                color={"main"}
                clearStatus={clearStatus}
                onClick={() => {
                  onClickFilterHandler("paid4");
                }}
              />
              <Button
                xs
                has_state
                label="架電"
                color={"main"}
                clearStatus={clearStatus}
                onClick={() => {
                  onClickFilterHandler("phone");
                }}
              />
            </Stack>
            <Stack direction={{ sm: "column", md: "row" }} spacing={1} sx={{}}>
              <Button
                s
                on={true}
                label={"新規作成"}
                clearStatus={clearStatus}
                color={"main"}
                onClick={openAddAppraisals}
              />
            </Stack>
          </Stack>
          <Stack direction="column" spacing={1} sx={{ maxWidth: "50%" }}>
            <Stack direction={{ md: "row", xl: "row" }} spacing={2}>
              <TextField
                id="outlined-basic"
                label="電話番号"
                name="phone_no"
                variant="outlined"
                onChange={onChangePhoneNumber}
                value={props.appraisalsQueryParams.phone_no}
                sx={{ width: "40%" }}
                size="small"
              />
              <TextField
                id="outlined-basic"
                name="kw"
                label="キーワード"
                variant="outlined"
                sx={{ width: "60%" }}
                onChange={onChangeKeyWordHandler}
                value={props.appraisalsQueryParams.kw}
                size="small"
              />
              <Button
                s
                on
                label="検索"
                color={"main"}
                onClick={onClickSearch}
              />
            </Stack>
            <Stack direction={{ md: "row", xl: "row" }} spacing={2}>
              <Select
                labelId="dropdown-label"
                id="dropdown"
                value={dropdownValue}
                onChange={handleDropDownChange}
                size="small"
                displayEmpty
                sx={{ minWidth: 120 }}
              >
                <MenuItem value={false}>申請日</MenuItem>
                <MenuItem value={true}>送信日</MenuItem>
              </Select>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{ textField: { size: "small" } }}
                  name="from"
                  label="From"
                  value={props.appraisalsQueryParams.from}
                  onChange={(value) => {
                    onChangeDate(value, "from");
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{ textField: { size: "small" } }}
                  name="to"
                  label="To"
                  value={props.appraisalsQueryParams.to}
                  onChange={(value) => {
                    onChangeDate(value, "to");
                  }}
                />
              </LocalizationProvider>
              <Button
                s
                on
                label="クリア"
                color={"gray"}
                onClick={onClickClear}
              />
            </Stack>
            <Stack
              direction={{ md: "row", xl: "row" }}
              spacing={3}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Typography> 件数 : {props.rowCount}</Typography>
              <Button
                s
                has_state
                clearStatus={clearStatus}
                label="テストデータ一覧"
                color={"main"}
                onClick={onClickTestData}
                width="100px"
              />
              <Button
                s
                label="CSV出力"
                color={"sub"}
                on
                onClick={generate_appraisal_list_report}
                disabled={downloading}
              />
            </Stack>
          </Stack>
        </Stack>
      </Box>
      {addAppraisals && (
        <Edit onClose={closeAddAppraisals} refresh={addAppraisals} />
      )}
    </div>
  );
};

export default FilterSectionAppraisals;
