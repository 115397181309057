
import { Radio as AntRadio, ConfigProvider } from 'antd';
import { RadioProps as AntRadioProps, RadioChangeEvent } from 'antd/es/radio';


export const Radio = ({
  optionType = 'default',
  options,
  ...props
}) => {
  
  return (
    <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#3f51b5', 
      },
      components: {
        Radio: {
          buttonCheckedBg: "#3f51b5",
          buttonBg: "#3f51b5",
          buttonColor: "#fff",
        },
      },
    }}
  >
    <div className="mb-4">
      <AntRadio.Group
        options={options}
        optionType={optionType}
        buttonStyle={(props).buttonStyle}
        size={(props).size}
        onChange={props.onChange}
        {...props}
      />
    </div>
    </ConfigProvider>
  );
};
