import { create } from "zustand";

const initialState = {
  emailList: [],
};

export const useMatchingCondtionsStore = create((set) => ({
  ...initialState,
  setEmailList: (emailList) => set({ emailList }),
}));
