import React from 'react';
import AntRow from 'antd/es/row';
import AntCol from 'antd/es/col';




export const Row = ({
  gutter = [16, 16],
  wrap = true,
  children,
  ...props
}) => {
  return (
    <AntRow gutter={gutter} wrap={wrap} {...props}>
      {children}
    </AntRow>
  );
};

export const Col = ({ span, children, ...props }) => {
  return (
    <AntCol span={span} {...props}>
      {children}
    </AntCol>
  );
};
