import Logo from '../../components/Logo';
import ReportList from './ReportList';
import { useEffect, useRef, useState } from 'react';
import Input from "../../components/input";
import DateTime from '../../components/datetime';
import Button from '../../components/button';
import { Link } from 'react-router-dom';
import { useBackend } from '../../hooks/useBackend';
import dayjs from 'dayjs';
import Logout from '../auth/logout';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

export default function ReportView() {
  const [data, setData] = useState([]);
  const from  =dayjs().subtract(1, 'month').format("YYYY/MM/DD")
  const to = dayjs().format("YYYY/MM/DD")
  const [f, setf] = useState(from);
  const [t, sett] = useState(to);
  const [loading, setIsLoading] = useState(false);
  const fr = useRef()
  const tr = useRef()
  const [kw, setkw] = useState("");
  const api = useBackend();
  const dateTimeValueFromRef = (r) => Array.from(r.current.children[0]?.children).filter(e => e.nodeName === "INPUT")[0]?.value
  const illegalDate = (t) =>  (t.match(/Y/)|| t.match(/M/) || t.match(/D/))
  useEffect(() => {
    const d = {
      "from" : from,
      "to" : to
    }
    setIsLoading(true);
      api.report(d)
      .then((e) => {
          setIsLoading(false);
          setData(e.data)
      })
  }, [])
  const apply = () => {
    const from =dateTimeValueFromRef(fr) ;
    const to = dateTimeValueFromRef(tr) ;
    setIsLoading(true);
    if (illegalDate(from)) return;
    if (illegalDate(to)) return;
    const d = {
      "kw": kw,
      "from": f,
      "to": t
    }
    setf(from);
    sett(to);
    api
      .report(d)
      .then((e) => {
        setIsLoading(false);
        setData(e.data);
      })
  }
  const input_kw = (d) => {
    setkw(d.kw)
  }
  const setfr = (d, c) => {
    const fv = d.format('YYYY/MM/DD');
    if(fv === 'Invalid Date') return ;
    setf(fv)
  }
  const setto = (d) => {
    sett(d.format("YYYY/MM/DD"))
  }
  return (
    <div className="reports">
      <div className="view_header vflex">
        <div className='flex view_header'>
          <div className='flex'>
            <Logo />
            <h1 className='space_around_small'>集計</h1>
          </div>
          <div className='flex'>
          <Link to="/console/dashboard" className='space_right'>
              <Button label="管理者ポータル" color={"sub"} on />
          </Link>
            <Logout />
            <Link to="/console/password-change" className='space_right_narrow'>
                <Button label="パスワード変更" color={"sub"} on />
            </Link>
            <Link to="/console/register" className='space_right_narrow'>
                <Button label="新規ユーザー登録" color={"sub"} on />
            </Link>
            <Link to="/console/appraisals" className='space_right'>
              <Button label="査定一覧へ" color={"sub"} on/>
            </Link>
          </div>
        </div>
        <div className='space_left small flex'>
          <div className="filter_input"> <Input label="買取店" id="kw" onChange={input_kw}/> </div>
          <div className="filter_input">
            <DateTime
              _ref={fr}
              label="From"
              name="from"
              id="from"
              noTime
              selected={f}
              />
          </div>
          <div className="filter_input">
            <DateTime
              _ref={tr}
              label="To"
              name="to"
              id="to"
              noTime
              selected={t}
              />
          </div>
          <div className="space_around_small"> <Button on small label="適用" color={"main"}  onClick={apply} /> </div>
          <div className="space_around_small"> <Button on small label="クリア" color={"gray"}  onClick={() => window.location.reload()} /> </div>
        </div>

        {/* <Button color={"main"} plus circle/> */}
      </div>
      {data && data.length && <ReportList data={data} />}
      {loading && (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: '100vh' }}
      >
        <CircularProgress />
      </Grid>
    )}
    </div>
  );
}

