import { create } from 'zustand';

const initialState = {
  selectedStates: {
    okCarTypes: false,
    okBodyTypes: false,
    okMakes: false,
    ngCarTypes: false,
    ngBodyTypes: false,
    ngMakes: false,
  },
};

export const useSelectAllStore = create((set) => ({
  ...initialState,
  setSelecteAllAction: (newState) =>
    set((state) => ({
      selectedStates: {
        ...state.selectedStates,
        ...newState,
      },
    })),
}));
