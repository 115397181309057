import React, { useState, useEffect } from "react";
import { Checkbox, Button } from "antd";

export const MultipleOptionList = ({
  firstOptions = [],
  secondOptions = {},
  onPrefectureChange,
  setInputs,
  inputs = [{ prefectures: "", manicipalities: "" }],
  disable,
}) => {
  const [errors, setErrors] = useState([]);
  const [selectedPrefecture, setSelectedPrefecture] = useState(null);
  const [allMunicipalitiesSelected, setAllMunicipalitiesSelected] =
    useState(false);
  const [displayInputs, setDisplayInputs] = useState([]);
  const [backendData, setBackendData] = useState([]);

  const consolidateMunicipalities = (inputs) => {
    return inputs.reduce((acc, input) => {
      if (!acc[input.prefectures]) {
        acc[input.prefectures] = [];
      }
      if (input.manicipalities === "全域") {
        acc[input.prefectures] = ["全域"];
      } else if (!acc[input.prefectures].includes("全域")) {
        acc[input.prefectures].push(input.manicipalities);
      }
      return acc;
    }, {});
  };

  const getDisplayMunicipalities = (input) => {
    if (!input.manicipalities) return "";

    // If the backend data shows "全域", return all municipality names for display
    if (input.manicipalities === "全域") {
      const municipalityOptions = secondOptions[input.prefectures] || [];
      return municipalityOptions.map((opt) => opt.value).join(",");
    }

    const consolidatedData = consolidateMunicipalities(backendData);
    if (consolidatedData[input.prefectures]) {
      return consolidatedData[input.prefectures].join(",");
    }

    return input.manicipalities;
  };

  useEffect(() => {
    if (inputs.some((input) => input.prefectures === "全国")) {
      const allPrefectures = firstOptions.map((option) => {
        const existingInput = inputs.find(
          (input) =>
            input.prefectures === option.value || input.prefectures === "全国"
        );
        return {
          prefectures: option.value,
          manicipalities: existingInput
            ? getDisplayMunicipalities(existingInput)
            : "",
        };
      });
      setDisplayInputs(allPrefectures);
      setBackendData([{ prefectures: "全国", manicipalities: "" }]);
    } else {
      const consolidatedData = consolidateMunicipalities(inputs);

      const orderedDisplayInputs = firstOptions
        .filter((option) => consolidatedData[option.value])
        .map((option) => ({
          prefectures: option.value,
          manicipalities: consolidatedData[option.value].join(","),
        }));

      if (orderedDisplayInputs.length === 0) {
        orderedDisplayInputs.push({ prefectures: "", manicipalities: "" });
      }

      setDisplayInputs(orderedDisplayInputs);
      setBackendData(inputs);
    }
  }, [inputs, firstOptions, secondOptions]);

  const updateBackendData = (newDisplayInputs) => {
    const allPrefecturesSelected = firstOptions.every((opt) =>
      newDisplayInputs.some((input) => input.prefectures === opt.value)
    );

    if (allPrefecturesSelected) {
      const municipalitySelections = newDisplayInputs.reduce((acc, input) => {
        if (input.manicipalities) {
          acc[input.prefectures] = input.manicipalities;
        }
        return acc;
      }, {});

      return firstOptions.flatMap((opt) => {
        const municipalities = municipalitySelections[opt.value];
        if (!municipalities) {
          return [
            {
              prefectures: opt.value,
              manicipalities: "",
            },
          ];
        }

        if (municipalities === "全域") {
          return [
            {
              prefectures: opt.value,
              manicipalities: "全域",
            },
          ];
        }

        return municipalities.split(",").map((municipality) => ({
          prefectures: opt.value,
          manicipalities: municipality.trim(),
        }));
      });
    }

    return newDisplayInputs
      .filter((input) => input.prefectures)
      .flatMap((input) => {
        if (!input.manicipalities) {
          return [
            {
              prefectures: input.prefectures,
              manicipalities: "",
            },
          ];
        }

        const municipalityOptions = secondOptions[input.prefectures] || [];
        const selectedMunicipalities = input.manicipalities.split(",");

        const allMunicipalitiesSelected =
          municipalityOptions.length > 0 &&
          municipalityOptions.length === selectedMunicipalities.length;

        if (allMunicipalitiesSelected) {
          return [
            {
              prefectures: input.prefectures,
              manicipalities: "全域",
            },
          ];
        }

        return selectedMunicipalities.map((municipality) => ({
          prefectures: input.prefectures,
          manicipalities: municipality.trim(),
        }));
      });
  };

  const handleSelectAllPrefectures = () => {
    const allSelected = firstOptions.every((opt) =>
      displayInputs.some((input) => input.prefectures === opt.value)
    );

    if (!allSelected) {
      const allPrefectures = firstOptions.map((option) => {
        const existingSelection = displayInputs.find(
          (input) => input.prefectures === option.value
        );
        return {
          prefectures: option.value,
          manicipalities: existingSelection
            ? existingSelection.manicipalities
            : "",
        };
      });
      setDisplayInputs(allPrefectures);
      const newBackendData = updateBackendData(allPrefectures);
      setBackendData(newBackendData);
      setInputs(newBackendData);
    } else {
      const emptyInput = [{ prefectures: "", manicipalities: "" }];
      setDisplayInputs(emptyInput);
      setBackendData(emptyInput);
      setInputs(emptyInput);
    }
    setSelectedPrefecture(null);
  };

  const handleSelectAllMunicipalities = () => {
    const newState = !allMunicipalitiesSelected;
    setAllMunicipalitiesSelected(newState);

    const selectedPrefectures = displayInputs.filter(
      (input) => input.prefectures
    );

    const newDisplayInputs = selectedPrefectures.map((input) => {
      const municipalityOptions = secondOptions[input.prefectures] || [];

      return {
        ...input,
        manicipalities: newState
          ? municipalityOptions.map((opt) => opt.value).join(",")
          : "",
      };
    });

    if (newDisplayInputs.length === 0) {
      newDisplayInputs.push({ prefectures: "", manicipalities: "" });
    }

    setDisplayInputs(newDisplayInputs);

    const newBackendData = newDisplayInputs.flatMap((input) => {
      if (!input.prefectures || !input.manicipalities) {
        return [
          {
            prefectures: input.prefectures,
            manicipalities: "",
            id:
              backendData.find((item) => item.prefectures === input.prefectures)
                ?.id || null,
            postalCode: null,
            wholePurchase: false,
          },
        ];
      }

      const municipalityOptions = secondOptions[input.prefectures] || [];

      if (newState && municipalityOptions.length > 0) {
        return [
          {
            prefectures: input.prefectures,
            manicipalities: "全域",
            id:
              backendData.find((item) => item.prefectures === input.prefectures)
                ?.id || null,
            postalCode: null,
            wholePurchase: false,
          },
        ];
      }

      if (!newState) {
        return [
          {
            prefectures: input.prefectures,
            manicipalities: "",
            id:
              backendData.find((item) => item.prefectures === input.prefectures)
                ?.id || null,
            postalCode: null,
            wholePurchase: false,
          },
        ];
      }

      return municipalityOptions.map((opt) => ({
        prefectures: input.prefectures,
        manicipalities: opt.value,
        id:
          backendData.find((item) => item.prefectures === input.prefectures)
            ?.id || null,
        postalCode: null,
        wholePurchase: false,
      }));
    });

    setBackendData(newBackendData);
    setInputs(newBackendData);
  };

  const handlePrefectureChange = (value, isSelected) => {
    if (isSelected) {
      const currentSelected = displayInputs
        .map((input) => input.prefectures)
        .filter((p) => p !== "");
      const willBeAllSelected =
        [...currentSelected, value].length === firstOptions.length;

      let newDisplayInputs;
      if (willBeAllSelected) {
        newDisplayInputs = firstOptions.map((option) => ({
          prefectures: option.value,
          manicipalities: "",
        }));
      } else {
        const newInput = { prefectures: value, manicipalities: "" };
        newDisplayInputs = [
          ...displayInputs.filter((p) => p.prefectures !== ""),
          newInput,
        ];
      }

      setDisplayInputs(newDisplayInputs);
      const newBackendData = updateBackendData(newDisplayInputs);
      setBackendData(newBackendData);
      setInputs(newBackendData);

      setSelectedPrefecture(value);
      if (onPrefectureChange) {
        onPrefectureChange(value, displayInputs.length);
      }
    } else {
      const filtered = displayInputs.filter(
        (input) => input.prefectures !== value
      );
      const newDisplayInputs = filtered.length
        ? filtered
        : [{ prefectures: "", manicipalities: "" }];

      setDisplayInputs(newDisplayInputs);
      const newBackendData = updateBackendData(newDisplayInputs);
      setBackendData(newBackendData);
      setInputs(newBackendData);

      if (selectedPrefecture === value) {
        setSelectedPrefecture(null);
      }
    }
  };

  const handleMunicipalityChange = (prefecture, value, isSelected) => {
    const newDisplayInputs = displayInputs.map((input) => {
      if (input.prefectures === prefecture) {
        const hasWholeArea = backendData.some(
          (item) =>
            item.prefectures === prefecture && item.manicipalities === "全域"
        );

        let currentMunicipalities;
        if (hasWholeArea) {
          const municipalityOptions = secondOptions[prefecture] || [];
          currentMunicipalities = municipalityOptions
            .map((opt) => opt.value)
            .filter((m) => m !== value);
        } else {
          currentMunicipalities = input.manicipalities
            ? input.manicipalities.split(",")
            : [];

          if (isSelected) {
            currentMunicipalities = [
              ...new Set([...currentMunicipalities, value]),
            ];
          } else {
            currentMunicipalities = currentMunicipalities.filter(
              (m) => m !== value
            );
          }
        }

        return {
          ...input,
          manicipalities: currentMunicipalities.join(","),
        };
      }
      return input;
    });

    setDisplayInputs(newDisplayInputs);

    const newBackendData = newDisplayInputs.flatMap((input) => {
      if (input.prefectures === prefecture) {
        const municipalityOptions = secondOptions[prefecture] || [];
        const selectedMunicipalities = input.manicipalities
          ? input.manicipalities.split(",")
          : [];

        if (selectedMunicipalities.length === 0) {
          return [
            {
              prefectures: prefecture,
              manicipalities: "",
            },
          ];
        }

        if (selectedMunicipalities.length === municipalityOptions.length) {
          return [
            {
              prefectures: prefecture,
              manicipalities: "全域",
            },
          ];
        }

        return selectedMunicipalities.map((municipality) => ({
          prefectures: prefecture,
          manicipalities: municipality.trim(),
        }));
      }

      return backendData.filter(
        (item) => item.prefectures === input.prefectures
      );
    });

    setBackendData(newBackendData);
    setInputs(newBackendData);
  };

  const isPrefectureSelected = (prefecture) => {
    return displayInputs.some((input) => input.prefectures === prefecture);
  };

  const isMunicipalitySelected = (prefecture, municipality) => {
    const hasWholeArea = backendData.some(
      (input) =>
        input.prefectures === prefecture && input.manicipalities === "全域"
    );

    if (hasWholeArea) {
      return true;
    }

    return backendData.some(
      (input) =>
        input.prefectures === prefecture &&
        input.manicipalities === municipality
    );
  };
  return (
    <div className="w-full">
      <div className="!bg-[#587c94] text-white font-bold p-4 flex items-center">
        <span className="text-sm">対象エリア</span>
      </div>

      <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="border rounded-lg p-4 bg-[#fafafa]">
          <div className="flex justify-between items-center mb-4">
            <div className="text-sm font-medium">都道府県</div>
            <Button
              size="small"
              onClick={handleSelectAllPrefectures}
              className="text-xs"
            >
              {firstOptions.every((opt) =>
                displayInputs.some((input) => input.prefectures === opt.value)
              )
                ? "全解除"
                : "全選択"}
            </Button>
          </div>
          <div className="max-h-[400px] overflow-y-auto">
            {firstOptions.map((option) => (
              <div key={option.value} className="py-1">
                <Checkbox
                  checked={isPrefectureSelected(option.value)}
                  onChange={(e) =>
                    handlePrefectureChange(option.value, e.target.checked)
                  }
                  disabled={disable}
                >
                  <span className="text-sm">{option.label}</span>
                </Checkbox>
              </div>
            ))}
          </div>
        </div>

        <div className="border rounded-lg p-4 bg-[#fafafa]">
          <div className="flex justify-between items-center mb-4">
            <div className="text-sm font-medium">市区町村・地域</div>
            {displayInputs.some((input) => input.prefectures) && (
              <Button
                size="small"
                onClick={handleSelectAllMunicipalities}
                className="text-xs"
              >
                全選択
              </Button>
            )}
          </div>
          <div className="max-h-[400px] overflow-y-auto">
            {displayInputs.some((input) => input.prefectures) ? (
              displayInputs
                .filter((input) => input.prefectures)
                .map((input, index) => {
                  const municipalityOptions =
                    secondOptions[input.prefectures] || [];
                  return (
                    <div key={index} className="mb-4">
                      <div className="text-sm font-medium mb-2">
                        {input.prefectures}
                      </div>
                      {municipalityOptions.map((option) => (
                        <div key={option.value} className="ml-4 py-1">
                          <Checkbox
                            checked={isMunicipalitySelected(
                              input.prefectures,
                              option.value
                            )}
                            onChange={(e) =>
                              handleMunicipalityChange(
                                input.prefectures,
                                option.value,
                                e.target.checked
                              )
                            }
                            disabled={disable}
                          >
                            <span className="text-sm">{option.label}</span>
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  );
                })
            ) : (
              <div className="text-sm text-gray-500">
                都道府県を選択してください
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="mt-4 border rounded-lg p-4"
        style={{ height: "200px", overflowY: "auto" }}
      >
        <div className="text-sm font-medium mb-4">選択中エリア</div>
        {displayInputs.some(
          (input) => input.prefectures && input.manicipalities
        ) ? (
          <div className="space-y-2">
            {displayInputs
              .filter((input) => input.prefectures && input.manicipalities)
              .map((input, index) => (
                <div key={index}>
                  <div className="font-medium">【{input.prefectures}】</div>
                  {input.manicipalities && (
                    <div className="text-sm text-gray-600 ml-4 mt-1">
                      {getDisplayMunicipalities(input).split(",").join("、")}
                    </div>
                  )}
                </div>
              ))}
          </div>
        ) : (
          <div className="text-gray-500">エリアが選択されていません</div>
        )}
      </div>
    </div>
  );
};

export default MultipleOptionList;
