import AntCheckbox from "antd/es/checkbox";
import {
  CheckboxProps as AntCheckboxProps,
  CheckboxChangeEvent,
} from "antd/es/checkbox";
import React from "react";


export const Checkbox = ({ type = "single", value, ...props }) => {
  if (type === "group") {
    const groupProps = props;
    return (
      <AntCheckbox.Group
        defaultValue={groupProps.defaultValue}
        options={groupProps.options}
        onChange={groupProps.onChange}
        value={groupProps.value}
      />
    );
  }

  const { label, onChange, typeCheck = true,...restProps } = props;
 

  return (
    typeCheck ? (
      <AntCheckbox
        onChange={(e) => onChange(e.target.checked)}
        value={value}
        className="text-[13px] sm:!text-[15px]"
        {...restProps}
      
      >
        {label}
      </AntCheckbox>
    ) : (
      <AntCheckbox
        onChange={(e) => onChange(e.target.checked)}
        value={value}
        className="text-[13px] sm:!text-[15px]"
        checked={value}
        {...restProps}
      >
        {label}
      </AntCheckbox>
    )
  );
};

