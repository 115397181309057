import { create } from 'zustand';

const initialState = {
  disabled: {
    selector1: false,
    selector2: false,
    selector3: false,
    option1: false,
    option2: false,
  },
};

export const useDisabledStore = create((set) => ({
  ...initialState,
  setDisabled: (newDisabled) =>
    set((state) => ({
      disabled: {
        ...state.disabled,
        ...newDisabled,
      },
    })),
}));
