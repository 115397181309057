import React, { useEffect, useState } from "react";
import PurchaseStoresListTable from "./table";
import PurchaseStoreListSearchSection from "./search-section";
import { generateCurrentYearMonth } from "../../utils/generate-current-year-month";
import { useLazyGetInvoicesQuery } from "../../app/services/invoices.api";
import { useAppStore } from "../../stores/app.store";

function PurchaseStoresListLayout(props) {
  const currentMonthYear = generateCurrentYearMonth();
  const [filterParams, setFilterParams] = useState({
    id: "0",
    name: "",
    year: currentMonthYear.year.toString(),
    month: currentMonthYear.month.toString(),
    page: "0",
    size: "1500",
    billing: false,
    debit: false,
    deleted: false,
    isBetween: false,
    fromYear: currentMonthYear.year.toString(),
    toYear: currentMonthYear.year.toString(),
    fromMonth: currentMonthYear.month.toString(),
    toMonth: currentMonthYear.month.toString(),
    fromDay: "1",
    toDay: "31",
    dateType: "monthly"
  });

  const [fetchInvoices, { data, isLoading, isFetching }] = useLazyGetInvoicesQuery();
  const { checkDelete } = useAppStore();

  useEffect(() => {
    const params = { ...filterParams };

    if (params.dateType === 'monthly') {
      params.isBetween = false;
      params.fromYear = "0";
      params.toYear = "0";
      params.fromMonth = "0";
      params.toMonth = "0";
      params.fromDay = "0";
      params.toDay = "0";
    } else {
      params.isBetween = true;
    }

    fetchInvoices(params);
  }, [checkDelete, filterParams]);

  const onClickFilter = (actionType) => {
    setFilterParams(prevParams => ({
      ...prevParams,
      [actionType.toLowerCase()]: !prevParams[actionType.toLowerCase()]
    }));
  };

  const onSearchFilter = () => {
    fetchInvoices(filterParams);
  };

  const onChangeFilter = (name, value) => {
    if (name === 'dateType') {
      const updatedParams = {
        ...filterParams,
        dateType: value
      };
      
      if (value === 'daily') {
        updatedParams.isBetween = true;
      } else {
        updatedParams.isBetween = false;
      }
      
      setFilterParams(updatedParams);
    } else {
      setFilterParams(prevParams => ({
        ...prevParams,
        [name]: value.toString()
      }));
    }
  };

  const onChangePagination = (pageIndex, pageSize) => {
    setFilterParams(prevParams => ({
      ...prevParams,
      page: pageIndex.toString(),
      size: pageSize.toString()
    }));
  };

  return (
    <div className="tabs-wrapper">
      <PurchaseStoreListSearchSection
        onChangeFilter={onChangeFilter}
        filterParams={filterParams}
        onSearchFilter={onSearchFilter}
        onClickFilter={onClickFilter}
        data={data ? data : []}
      />
      <br />
      <PurchaseStoresListTable
        filterParams={filterParams}
        data={data !== undefined ? data : null}
        onChangePagination={onChangePagination}
        isLoading={isLoading || data === undefined || isFetching}
      />
    </div>
  );
}

export default PurchaseStoresListLayout;