import axiosInstance from './axiosInstance';

export const httpGet = (url) => {
  return axiosInstance.get(url);
};

export const httpPost = (url, data) => {
  return axiosInstance.post(url, data);
};

export const httpPut = (url, data) => {
  return axiosInstance.put(url, data);
};

export const httpPatch = (url, data) => {
  return axiosInstance.patch(url, data);
};

export const httpDelete = (url) => {
  return axiosInstance.delete(url);
};
