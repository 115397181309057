import React, { useEffect } from "react";
import AssessmentRequestListTable from "./table";
import { MainWrapper, TableLoadingSkeleton } from "../../components";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useLazyGetInvoiceDetailsQuery } from "../../app/services/invoices.api";
import NavigationBilling from "../../components/navigate-page/NavigationBilling";

function AssessmentRequestList() {
  const { shopId } = useParams();
  const { main } = useParams();
  const { mainId } = useParams();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const [fetchInvoiceDetails, { data, isLoading }] =
    useLazyGetInvoiceDetailsQuery();
    useEffect(() => {
      if (shopId && main && mainId) {
        const isBetween = searchParams.get('isBetween') === 'true';
        
        const params = {
          shopId,
          year: searchParams.get('year'),
          month: searchParams.get('month'),
          attribute: searchParams.get('attribute'),
          type: searchParams.get('type'),
          main,
          mainId,
          isBetween,
          fromYear: isBetween ? searchParams.get('fromYear') || '0' : '0',
          toYear: isBetween ? searchParams.get('toYear') || '0' : '0',
          fromMonth: isBetween ? searchParams.get('fromMonth') || '0' : '0',
          toMonth: isBetween ? searchParams.get('toMonth') || '0' : '0',
          fromDay: isBetween ? searchParams.get('fromDay') || '0' : '0',
          toDay: isBetween ? searchParams.get('toDay') || '0' : '0',
        };
  
        fetchInvoiceDetails(params);
      }
    }, [shopId, main, mainId, searchParams, fetchInvoiceDetails]);
  return (
    <MainWrapper>
      <NavigationBilling mainId={mainId} />
      <div>
        {isLoading && data === undefined ? (
          <>
            <TableLoadingSkeleton />
          </>
        ) : (
          <AssessmentRequestListTable data={data ? data : []} />
        )}
      </div>
    </MainWrapper>
  );
}

export default AssessmentRequestList;
