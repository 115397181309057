export const yearList = [
  { value: null, label: "（指定なし）" },
  { value: "3000", label: "（現在まで）" },
  { value: "2023", label: "令和5年 (2023)" },
  { value: "2022", label: "令和4年 (2022)" },
  { value: "2021", label: "令和3年 (2021)" },
  { value: "2020", label: "令和2年 (2020)" },
  { value: "2019", label: "令和1年 (2019)" },
  { value: "2018", label: "平成30年 (2018)" },
  { value: "2017", label: "平成29年 (2017)" },
  { value: "2016", label: "平成28年 (2016)" },
  { value: "2015", label: "平成27年 (2015)" },
  { value: "2014", label: "平成26年 (2014)" },
  { value: "2013", label: "平成25年 (2013)" },
  { value: "2012", label: "平成24年 (2012)" },
  { value: "2011", label: "平成23年 (2011)" },
  { value: "2010", label: "平成22年 (2010)" },
  { value: "2009", label: "平成21年 (2009)" },
  { value: "2008", label: "平成20年 (2008)" },
  { value: "2007", label: "平成19年 (2007)" },
  { value: "2006", label: "平成18年 (2006)" },
  { value: "2005", label: "平成17年 (2005)" },
  { value: "2004", label: "平成16年 (2004)" },
  { value: "2003", label: "平成15年 (2003)" },
  { value: "2002", label: "平成14年 (2002)" },
  { value: "2001", label: "平成13年 (2001)" },
  { value: "2000", label: "平成12年 (2000)" },
  { value: "1999", label: "平成11年 (1999)" },
  { value: "1998", label: "平成10年 (1998)" },
  { value: "1997", label: "平成9年 (1997)" },
  { value: "1996", label: "平成8年 (1996)" },
  { value: "1995", label: "平成7年 (1995)" },
  { value: "1994", label: "平成6年 (1994)" },
  { value: "1993", label: "平成5年 (1993)" },
  { value: "1992", label: "平成4年 (1992)" },
  { value: "1991", label: "平成3年 (1991)" },
  { value: "1990", label: "平成2年 (1990)" },
  { value: "1989", label: "平成1年 (1989)" },
  { value: "1900", label: "1989年（以前も含む）" },
  { value: "1988", label: "昭和63年 (1988)" },
  { value: "1987", label: "昭和62年 (1987)" },
  { value: "1986", label: "昭和61年 (1986)" },
  { value: "1985", label: "昭和60年 (1985)" },
  { value: "1984", label: "昭和59年 (1984)" },
  { value: "1983", label: "昭和58年 (1983)" },
  { value: "1982", label: "昭和57年 (1982)" },
  { value: "1981", label: "昭和56年 (1981)" },
  { value: "1980", label: "昭和55年 (1980)" },
  { value: "1979", label: "昭和54年 (1979)" },
  { value: "1978", label: "昭和53年 (1978)" },
  { value: "1977", label: "昭和52年 (1977)" },
  { value: "1976", label: "昭和51年 (1976)" },
  { value: "1975", label: "昭和50年 (1975)" },
  { value: "1974", label: "昭和49年 (1974)" },
  { value: "1973", label: "昭和48年 (1973)" },
  { value: "1972", label: "昭和47年 (1972)" },
  { value: "1971", label: "昭和46年 (1971)" },
  { value: "1970", label: "昭和45年 (1970)" },
  { value: "1969", label: "昭和44年 (1969)" },
  { value: "1968", label: "昭和43年 (1968)" },
  { value: "1967", label: "昭和42年 (1967)" },
];

export const distanceList = [
  { value: null, label: "（指定なし）" },
  { value: "0km", label: "～0Km" },
  { value: "5,000km", label: "～5,000Km" },
  { value: "10,000km", label: "～10,000Km" },
  { value: "20,000km", label: "～20,000Km" },
  { value: "30,000km", label: "～30,000Km" },
  { value: "40,000km", label: "～40,000Km" },
  { value: "50,000km", label: "～50,000Km" },
  { value: "60,000km", label: "～60,000Km" },
  { value: "70,000km", label: "～70,000Km" },
  { value: "80,000km", label: "～80,000Km" },
  { value: "90,000km", label: "～90,000Km" },
  { value: "100,000km", label: "～100,000Km" },
  { value: "110,000km", label: "～110,000Km" },
  { value: "120,000km", label: "～120,000Km" },
  { value: "130,000km", label: "～130,000Km" },
  { value: "140,000km", label: "～140,000Km" },
  { value: "150,000km", label: "～150,000Km" },
  { value: "160,000km", label: "～160,000Km" },
  { value: "170,000km", label: "～170,000Km" },
  { value: "180,000km", label: "～180,000Km" },
  { value: "190,000km", label: "～190,000Km" },
  { value: "200,000km", label: "～200,000Km" },
  { value: "210,000km", label: "～210,000Km" },
  { value: "900000000km", label: "210,000Km（以上も含む）" },
];
