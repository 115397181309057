import { useEffect, useMemo, useRef, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Button, Stack } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ModalComponent } from "../../components";
import DepositModal from "./deposit-modal";
import { TableLoadingSkeleton } from "../../components";
import PdfGeneration from "./invoice-pdf/pdf-generation";
import { object } from "prop-types";
import { useAppStore } from "../../stores/app.store";
import { deleteShop, updateShopBuyer } from "../../app/services/shop.service";
import {
  setCustomErrorToast,
  setSuccessToast,
} from "../../utils/notifications";

export default function PurchaseStoresListTable(props) {
  const navigate = useNavigate();
  const yearRef = useRef("");
  const monthRef = useRef("");
  const shopIdRef = useRef("");
  const depositStatusRef = useRef("");
  const [branch, setBranch] = useState("");
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const {
    setEditRegisterFormStatus,
    setEditRegisterId,
    setFormTabIndex,
    setMatchingConditonTabIndex,
    setCheckDelete,
    checkDelete,
  } = useAppStore();

  const companyId = useRef(0);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 1000,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenInvoicePdf, setIsOpenInvoicePdf] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleOpenInvoicePdf = () => setIsOpenInvoicePdf(true);
  const handleCloseInvoicePdf = () => setIsOpenInvoicePdf(false);

  useEffect(() => {
    props.onChangePagination(pagination.pageIndex, pagination.pageSize);
  }, [pagination.pageIndex, pagination.pageSize]);

  const handleShopDelete = async (id) => {
    try {
      const response = await deleteShop(id);
      setSuccessToast("削除が完了しました");
      setIsOpenDelete(false);
      setCheckDelete(!checkDelete);
    } catch (error) {
      console.log(error);
      setCustomErrorToast("問題が発生しました");
      setIsOpenDelete(false);
    }
  };

  const combineDuplicateBranches = (data) => {
    if (!data?.list) return data;

    const processedData = structuredClone(data);
    
    processedData.list = processedData.list.map(item => {
      if (!item.branches?.length) return item;

      // Create a map to store combined branch data
      const branchMap = new Map();

      // Process each branch
      item.branches.forEach(branch => {
        if (branchMap.has(branch.id)) {
          // Combine values for duplicate branches
          const existing = branchMap.get(branch.id);
          branchMap.set(branch.id, {
            ...existing,
            amount: (existing.amount || 0) + (branch.amount || 0),
            totalNumberOfGuest: (existing.totalNumberOfGuest || 0) + (branch.totalNumberOfGuest || 0),
            totalNumberRejectionInLastMonth: (existing.totalNumberRejectionInLastMonth || 0) + (branch.totalNumberRejectionInLastMonth || 0),
            totalNumberRejection: (existing.totalNumberRejection || 0) + (branch.totalNumberRejection || 0)
          });
        } else {
          branchMap.set(branch.id, { ...branch });
        }
      });

      return {
        ...item,
        branches: Array.from(branchMap.values())
      };
    });

    return processedData;
  };


  const calculateAmount = () => {
    let amount = 0;
    props.data?.list.forEach((item) => {
      amount += item.amount;
    });
    return amount;
  };

  const calculatePassengers = () => {
    let totalNumberOfGuest = 0;
    props.data?.list.forEach((item) => {
      totalNumberOfGuest += item.totalNumberOfGuest;
    });
    return totalNumberOfGuest;
  }

  const calculateNumberRejectionInLastMonth = () => {
    let totalNumberRejectionInLastMonth = 0;
    props.data?.list.forEach((item) => {
      totalNumberRejectionInLastMonth += item.totalNumberRejectionInLastMonth;
    });
    return totalNumberRejectionInLastMonth;
  }

  const calculateTotalNumberRejection = () => {
    let totalNumberRejection = 0;
    props.data?.list.forEach((item) => {
      totalNumberRejection += item.totalNumberRejection;
    });
    return totalNumberRejection;
  }


  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        enableHiding: false,
        maxSize: 100,
        header: "店舗ID",
        sortingFn: (value1, value2) => {
          if (value2.original.id === null) {
            return 0;
          } else return value1.original.id - value2.original.id;
        },
      },
      {
        accessorKey: "companyName",
        enableSorting: false,
        enableHiding: false,
        maxSize: 100,
        header: "会社名",
      },
      {
        accessorKey: "name",
        enableSorting: false,
        enableHiding: false,
        maxSize: 100,
        header: "店名",
      },
      {
        accessorKey: "shopTypeEnum",
        enableSorting: false,
        enableHiding: false,
        maxSize: 100,
        header: " 組織区分",
        Cell: ({ renderedCellValue }) => {
          if (renderedCellValue === "HEAD_BRANCH") {
            setBranch(renderedCellValue);
            return <span>本社</span>;
          } else if (renderedCellValue === "SUB_BRANCH") {
            setBranch(renderedCellValue);
            return <span>支店</span>;
          } else {
            setBranch(renderedCellValue);
            return <span></span>;
          }
        },
      },
      {
        accessorKey: "amount",
        enableSorting: false,
        enableHiding: false,
        maxSize: 100,
        header: " 指定月請求額",
        Cell: ({ row, renderedCellValue }) => {
          if (row?.index === 0 && row?.original?.branches?.length === 0) {
            return calculateAmount();
          } else {
            return <span>{renderedCellValue}</span>;
          }
        },
      },
      {
        accessorKey: "totalNumberOfGuest",
        enableHiding: false,
        maxSize: 100,
        header: "指定月送客数",
        sortingFn: (value1, value2) => {
          if (value2.original.id === null) {
            return 0;
          } else
            return (
              value1.original.totalNumberOfGuest -
              value2.original.totalNumberOfGuest
            );
        },
        Cell: ({ renderedCellValue, row }) => {
          if (row?.original?.shopTypeEnum === "HEAD_BRANCH") {
            companyId.current = row.original.id;
          }
          if (row?.index === 0 && row?.original?.branches?.length === 0) {
            return <span>{calculatePassengers()}</span>;
          } else {
            const baseUrl = `/console/assessment-request-list/${row.original.id}/${row?.original?.shopTypeEnum}/${companyId.current}`;
            const params = new URLSearchParams({
              previousPage: `/console/dashboard?tab=${0}`,
              year: row?.original?.year,
              month: row?.original?.month,
              attribute: "ASSESSED",
              type: "BOTH",
            });

            if (props.filterParams.isBetween) {
              params.append('isBetween', 'true');
              params.append('fromYear', props.filterParams.fromYear);
              params.append('toYear', props.filterParams.toYear);
              params.append('fromMonth', props.filterParams.fromMonth);
              params.append('toMonth', props.filterParams.toMonth);
              params.append('fromDay', props.filterParams.fromDay);
              params.append('toDay', props.filterParams.toDay);
            }

            return (
              <Link to={`${baseUrl}?${params.toString()}`}>
                <span className="table-row-clickable-item">
                  {renderedCellValue}
                </span>
              </Link>
            );
          }
        },
      },
      {
        accessorKey: "totalNumberRejectionInLastMonth",
        enableHiding: false,
        maxSize: 100,
        header: "指定月前月却下数",
        sortingFn: (value1, value2) => {
          if (value2.original.id === null) {
            return 0;
          } else
            return (
              value1.original.totalNumberRejectionInLastMonth -
              value2.original.totalNumberRejectionInLastMonth
            );
        },
        Cell: ({ renderedCellValue, row }) => {
          if (row?.original?.shopTypeEnum === "HEAD_BRANCH") {
            companyId.current = row.original.id;
          }
          if (row?.index === 0 && row?.original?.branches?.length === 0) {
            return (
              <span>{calculateNumberRejectionInLastMonth()}</span>
            );
          } else {
            const baseUrl = `/console/assessment-request-list/${row.original.id}/${row?.original?.shopTypeEnum}/${companyId.current}`;
            const params = new URLSearchParams({
              previousPage: `/console/dashboard?tab=${0}`,
              year: row?.original?.year,
              month: row?.original?.month,
              attribute: "LREJ",
              type: "BOTH",
            });

            if (props.filterParams.isBetween) {
              params.append('isBetween', 'true');
              params.append('fromYear', props.filterParams.fromYear);
              params.append('toYear', props.filterParams.toYear);
              params.append('fromMonth', props.filterParams.fromMonth);
              params.append('toMonth', props.filterParams.toMonth);
              params.append('fromDay', props.filterParams.fromDay);
              params.append('toDay', props.filterParams.toDay);
            }

            return (
              <Link to={`${baseUrl}?${params.toString()}`}>
                <span className="table-row-clickable-item">
                  {renderedCellValue}
                </span>
              </Link>
            );
          }
        },
      },
      {
        accessorKey: "totalNumberRejection",
        enableHiding: false,
        maxSize: 100,
        header: "指定月却下数",
        sortingFn: (value1, value2) => {
          if (value2.original.id === null) {
            return 0;
          } else
            return (
              value1.original.totalNumberRejection -
              value2.original.totalNumberRejection
            );
        },
        Cell: ({ renderedCellValue, row }) => {
          if (row?.original?.shopTypeEnum === "HEAD_BRANCH") {
            companyId.current = row.original.id;
          }
          if (row?.index === 0 && row?.original?.branches?.length === 0) {
            return <span>{calculateTotalNumberRejection()}</span>;
          } else {
            const baseUrl = `/console/assessment-request-list/${row.original.id}/${row?.original?.shopTypeEnum}/${companyId.current}`;
            const params = new URLSearchParams({
              previousPage: `/console/dashboard?tab=${0}`,
              year: row?.original?.year,
              month: row?.original?.month,
              attribute: "REJ",
              type: "BOTH",
            });

            if (props.filterParams.isBetween) {
              params.append('isBetween', 'true');
              params.append('fromYear', props.filterParams.fromYear);
              params.append('toYear', props.filterParams.toYear);
              params.append('fromMonth', props.filterParams.fromMonth);
              params.append('toMonth', props.filterParams.toMonth);
              params.append('fromDay', props.filterParams.fromDay);
              params.append('toDay', props.filterParams.toDay);
            }

            return (
              <Link to={`${baseUrl}?${params.toString()}`}>
                <span className="table-row-clickable-item">
                  {renderedCellValue}
                </span>
              </Link>
            );
          }
        },
      },
      {
        accessorKey: "depositStatus",
        enableSorting: false,
        enableHiding: false,
        maxSize: 100,
        header: "指定月前月入金ST",
        Cell: ({ renderedCellValue, row }) => {
          return row?.original?.depositStatus !== null ? (
            <span
              className="table-row-clickable-item"
              onClick={() => {
                yearRef.current = row?.original?.year;
                monthRef.current = row?.original?.month;
                shopIdRef.current = row?.original?.id;
                depositStatusRef.current = row?.original?.depositStatus;
                handleOpen();
              }}
            >
              {renderedCellValue !== "BEFORE_DEPOSIT" ? "入金済み" : "入金前"}
            </span>
          ) : (
            <span style={{ color: "grey" }}></span>
          );
        },
      },
      {
        accessorKey: "paymentMethod",
        enableSorting: false,
        enableHiding: false,
        maxSize: 100,
        header: "引落",
        Cell: ({ renderedCellValue, row }) => {
          return row?.original?.shopTypeEnum === "HEAD_BRANCH" ? (
            <span>{renderedCellValue === "DEBIT" ? "引落" : "請求"} </span>
          ) : (
            ""
          );
        },
      },
      {
        accessorKey: "billingStatus",
        enableSorting: false,
        enableHiding: false,
        maxSize: 100,
        header: "前月請求ST",
        Cell: ({ renderedCellValue, row }) => {
          return row?.original?.shopTypeEnum === "HEAD_BRANCH" ? (
            <span>
              {renderedCellValue === "BEFORE_REQUEST" ? "請求前" : "請求済み"}
            </span>
          ) : (
            ""
          );
        },
      },
      {
        accessorKey: "actionButtons",
        enableSorting: false,
        enableHiding: false,
        size: 600,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}></p>
        ),

        Cell: ({ row }) => {
          if (row.index === 0 && !row.original.id) {
            return <></>;
          }
          return (
            <div className=" flex items-center justify-start pl-2 gap-4 w-full ">
              <Button
                variant="contained"
                onClick={() => {
                  setEditRegisterFormStatus(true);
                  setFormTabIndex(5);
                  setEditRegisterId(row.original.id);
                }}
              >
                編集
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setIsOpenDelete(true);
                  setDeleteId(row.original.id);
                }}
              >
                削除
              </Button>

              <Button
                variant="outlined"
                className=" !text-[#f25f2a] !font-bold !border-2 !border-[#f25f2a]"
                onClick={() => {
                  setEditRegisterFormStatus(true);
                  setMatchingConditonTabIndex(6);
                  setEditRegisterId(row.original.id);
                }}
              >
                マッチング条件
              </Button>
              {row?.original?.shopTypeEnum === "HEAD_BRANCH" ? (
                <>
                  <Button
                    variant="contained"
                    onClick={() => {
                      navigate(`/console/billing/${row?.original?.id}`, {
                        state: {
                          previousPage: `/console/dashboard?tab=${0}`,
                          companyName: row?.original?.companyName,
                        },
                      });
                    }}
                  >
                    請求確認
                  </Button>
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() => {
                      yearRef.current = row.original.year;
                      monthRef.current = row.original.month;
                      shopIdRef.current = row.original.id;
                      handleOpenInvoicePdf();
                    }}
                  >
                    請求書レビュー
                  </Button>
                </>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
    ],
    [props.data]
  );

  const tableRows = (list) => {
    const obj = {
      id: null,
      companyName: null,
      name: null,
      shopTypeEnum: null,
      countAssessedEx: null,
      countAssessedNonEx: null,
      totalNumberOfGuest: "20000000",
      rejectionInMonthEx: null,
      rejectionInMonthNonEx: null,
      totalNumberRejection: null,
      rejectionInLastMonthEx: null,
      rejectionInLastMonthNonEx: null,
      totalNumberRejectionInLastMonth: null,
      cancellationInLastMonthEx: null,
      cancellationInLastMonthNonEx: null,
      totalNumberCancellationInLastMonth: null,
      exclusiveAmount: null,
      nonExclusiveAmount: null,
      amount: null,
      ex: null,
      paymentMethod: null,
      referral: null,
      introduction: null,
      billingStatus: null,
      depositStatus: null,
      year: null,
      month: null,
      japaneeseMonth: null,
      lastDate: null,
      deadLine: null,
      lastMonthNegEx: null,
      lastMonthNegNonEx: null,
      lastMonthNegTotal: null,
      lastTwoMonthNegEx: null,
      lastTwoMonthNegNonEx: null,
      lastTwoMonthNegTotal: null,
      reInvoice: null,
      branches: [],
    };
    const cloneList = structuredClone(list);
    cloneList?.unshift(obj);
    return cloneList;
  };

  const table = useMaterialReactTable({
    columns,
    data: tableRows(combineDuplicateBranches(props.data)?.list || []),
    getSubRows: (row) => row.branches,
    enableExpanding: true,
    enableRowSelection: false,
    enableColumnOrdering: false,
    enableGlobalFilter: false,
    enableTopToolbar: false,
    enableSorting: true,
    enableFilters: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    rowCount: props.data ? props.data?.totalElement : 0, // This should come with backend response
    state: {
      pagination,
    },
    initialState: {
      columnPinning: { right: ["actionButtons"] },
    },
    onPaginationChange: setPagination,
    manualPagination: true,
    localization: {
      rowsPerPage: "ページあたりの行数",
      of: "の",
    },
  });

  return (
    <div className="material-react-table-body">
      {/* <CustomTableContainer /> */}
      {props.isLoading ? (
        <TableLoadingSkeleton />
      ) : (
        <MaterialReactTable table={table} />
      )}
      <ModalComponent width={300} isOpen={isOpen} handleClose={handleClose}>
        <DepositModal
          handleClose={handleClose}
          year={yearRef.current}
          month={monthRef.current}
          shopId={shopIdRef.current}
          status={depositStatusRef.current}
        />
      </ModalComponent>
      {isOpenDelete && (
        <div className=" w-screen h-screen fixed bg-transparent top-0 left-0 z-[80]">
          <div className=" w-full h-full flex items-center justify-center ">
            <div className=" bg-white flex flex-col items-center justify-center gap-4 w-4/12 h-[20vh] p-4 shadow-sm shadow-[#f25f2a] border-[#f25f2a] border ">
              <h1>本当に削除しますか？ </h1>
              <div className=" flex items-center gap-4">
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsOpenDelete(false);
                  }}
                >
                  いいえ
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleShopDelete(deleteId);
                  }}
                >
                  はい
                </Button>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      )}
      <ModalComponent
        width={800}
        isOpen={isOpenInvoicePdf}
        handleClose={handleCloseInvoicePdf}
      >
        <PdfGeneration
          handleClose={handleCloseInvoicePdf}
          year={yearRef.current}
          month={monthRef.current}
          shopId={shopIdRef.current}
        />
      </ModalComponent>
    </div>
  );
}
