import { forwardRef, useRef } from 'react'; 
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';

const StyledDateTime = styled(DateTimePicker)({
  '& .MuiOutlinedInput-input': {
    border: 0,
    fontSize: 'smaller' // Added quotes and unit 'px'
  }
});

export default forwardRef(function DateTime(props,ref) {
  
  const r = useRef()
  const value = () => r.current.value;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
      <div className={`input-norm ${ props.sparse ? "sparse" : props.narrow ? "narrow-v2" : "" }`}>
        <span className="label font_input_label">{props.label}</span>
        <StyledDateTime 
        ref={props._ref}
        inputRef={r}
        onChange={props.onChange}
        className='datetime' 
        ampm={false}
        closeOnSelect={false}
        format={props.noTime ? 'YYYY/MM/DD' : 'YYYY/MM/DD HH:mm:ss'}
        value={props.selected ? dayjs(props.selected) : ""}
        />
      </div>
    </LocalizationProvider>
  );
});