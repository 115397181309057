import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const appraisalsApi = createApi({
  reducerPath: "appraisalsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_ENDPOINT}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Appraisals"],
  endpoints: (builder) => ({
    getAppraisals: builder.query({
      query: (appraisalsQueryParams) => ({
        url: `/appraisal`,
        method: "GET",
        params: {
            ...appraisalsQueryParams
        },
      }),
      providesTags: [{ type: "Appraisals" }],
    }),
    getCSVAppraisals: builder.query({
      query: (appraisalsQueryParams) => ({
        url: `/assessedAppraisal/csv"`,
        method: "GET",
        params: {
            ...appraisalsQueryParams
        },
      }),
      providesTags: [{ type: "Appraisals" }],
    }),
  }),
});

export const { useGetAppraisalsQuery, useLazyGetAppraisalsQuery, useLazyGetCSVAppraisalsQuery } =
  appraisalsApi;
