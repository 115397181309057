import { create } from "zustand";

const initialState = {
  formTabIndex: false,
  matchingCondtionTabIndex:false,
  editRegisterFormStatus: null,
  editRegisterId: null,
  checkSelectAllState: false,
  mainTabIndex: null,
  isTabPage:false,
  checkDelete:false
};

export const useAppStore = create((set) => ({
  ...initialState,
  setFormTabIndex: (formTabIndex) => set((state) => ({ formTabIndex })),
  setIsTabPageAction: (isTabPage) => set((state) => ({ isTabPage })),
  setMainTabIndex: (mainTabIndex) => set((state) => ({ mainTabIndex })),
  setMatchingConditonTabIndex: (matchingCondtionTabIndex) => set((state) => ({ matchingCondtionTabIndex })),
  setSelectAllAction: (checkSelectAll) => set((state) => ({ checkSelectAll })),
  setEditRegisterFormStatus: (editRegisterFormStatus) =>
    set((state) => ({ editRegisterFormStatus })),
  setEditRegisterId: (editRegisterId) => set((state) => ({ editRegisterId })),
  setCheckDelete : (checkDelete) => set((state) => ({checkDelete}) )
}));



