import { useState } from "react";

export default function Input(props) {
  const [selected, setSelected] = useState(props.selected);
  const onChange = (e) => {
    setSelected(e.target.value);
    let d = {};
    d[props.id] = e.target.value;
    props.onChange(d);
  };
  return (
    <div
      className={`input-norm ${
        props.sparse ? "sparse" : props.narrow ? "narrow" : ""
      }`}
    >
      <span className="label font_input_label">{props.label}</span>
      <input
        type={props.password ? "password" : "text"}
        className="input font_input_content"
        value={selected ? selected : ""}
        onChange={onChange}
      ></input>
    </div>
  );
}
