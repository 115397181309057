import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const noticesApi = createApi({
  reducerPath: "noticesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_ENDPOINT_V2}`,
  }),
  tagTypes: ["Notices"],
  endpoints: (builder) => ({
    getNotices: builder.query({
      query: (params) => ({
        url: `/notice/private`,
        method: "GET",
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        },
        params: {
            ...params
        },
      }),
      providesTags: [{ type: "Notices" }],
    }),
    createNotice: builder.mutation({
      query: ({title, category, content}) => {
        let bodyFormData = new FormData();
        bodyFormData.append('title', title);
        bodyFormData.append('category', category);
        bodyFormData.append('content', content);
        return {
          url: '/notice/private',
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          },
          body: bodyFormData,
          formData:true   
        };
      }
    }),
    updateNotice: builder.mutation({
      query: ({id, title, category, content}) => {
        let bodyFormData = new FormData();
        bodyFormData.append('title', title);
        bodyFormData.append('category', category);
        bodyFormData.append('content', content);
        return {
          url: `/notice/private/${id}`,
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          },
          body: bodyFormData,
          formData:true   
        };
      }
    }),
    deleteNotice: builder.mutation({
      query: (id) => {
        return {
          url: `/notice/private/${id}`,
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          },
        };
      },
      invalidatesTags: ["Notices"],
    }),
  }),
});

export const { useGetNoticesQuery, useLazyGetNoticesQuery, useCreateNoticeMutation, useUpdateNoticeMutation, useDeleteNoticeMutation } = noticesApi;
