import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "../../components/tabs-panel";
import GeneralNoticePanel from "./general-panel";
import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BuyerNoticePanel from "./buyer-panel";
import SellerNoticePanel from "./seller-panel";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function NoticeLayout() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div>
      <Stack
        direction="row"
        justifyContent={"flex-end"}
        sx={{ marginBottom: "15px" }}
      >
        <div>
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/console/notice/create`, {
                state: {
                  previousPage: "/console/dashboard",
                  previousPageSubLevel: "/console/appraisals",
                },
              });
            }}
          >
            新規ニュース追加
          </Button>
        </div>
      </Stack>
      <Box sx={{ bgcolor: "background.paper", width: "100wh" }}>
        <AppBar position="static">
          <Tabs
            className="tab-mui"
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="white"
            variant="fullWidth"
            aria-label="full width tabs example"
            sx={{
              "& .MuiTab-root": {
                borderRight: "2px solid white", 
              },
              "& .MuiTab-root:last-child": {
                borderRight: "none", 
              },
            }}
          >
            <Tab label="全ユーザー" {...a11yProps(0)} />
            <Tab label="買取店" {...a11yProps(1)} />
            <Tab label="エンドユーザー" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <GeneralNoticePanel />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <BuyerNoticePanel />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <SellerNoticePanel />
        </TabPanel>
      </Box>
    </div>
  );
}
