import { useState } from 'react';
import Button from '../../components/button';
import Input from '../../components/input';
import Logo from '../../components/Logo'
import { useBackend } from '../../hooks/useBackend';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthUserContext } from './AuthContext';

export default function PasswordChangeView() {
  const api = useBackend();
  const [msg, setMsg] = useState("")
  const [pass, setPass] = useState("");
  const login = async () => {
    await api
      .pass_change(pass.password)
      .then(async (e) => setMsg("パスワードを更新しました。"))
      .catch((e) => {
        setMsg("失敗しました。画面を再読み込みの上再度実施してください。")
        throw e;
      });
  };
  return (
    <div className="root">
        <div className="login_form_container">
          <div className='space_side_large space_vertical_middle'>
            <h1 className='text-center'>パスワード変更</h1>
            <div>{msg}</div>
          </div>
          <div className='space_side_large space_vertical_middle'>
            <Input label="パスワード" id="password"  password onChange={setPass}/>
          </div>
          <div className='space_large'>
            <Button label="変更" color={"main"} main on onClick={login}></Button>
            <Link to="/console/appraisals" className='space_right'>
              <Button label="査定一覧へ" color={"sub"} on />
            </Link>
          </div>
        </div>
    </div>
  );
}