import Button from "../../components/button";
import { useEffect, useRef, useState } from "react";
import { useBackend } from "../../hooks/useBackend";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid } from "@mui/material";
import { styled, lighten, darken } from "@mui/system";
import { CircularProgress } from "@mui/material";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: "#2b2b2b",
  backgroundColor: "#f2f2f2",
  fontWeight: "600",
}));

const GroupItems = styled("ul")({
  padding: 0,
});

export default function Shop(props) {
  const [data, _] = useState(props.data);
  const [shop, setShop] = useState([]);
  const [allShops, setAllShops] = useState([]);
  const [shopList, setShopList] = useState([]);
  const [options, setOptions] = useState([]);
  const [first_shop, setFirstShop] = useState(
    data?.shops?.shops[0]?.shopid?.content
  );
  const [selectedOptionFirst, setSelectedOptionFirst] = useState(null);
  const [second_shop, setSecondShop] = useState(
    data?.shops?.shops[1]?.shopid?.content
  );
  const [selectedOptionSecond, setSelectedOptionSecond] = useState(null);
  const [third_shop, setThirdShop] = useState(
    data?.shops?.shops[2]?.shopid?.content
  );
  const [selectedOptionThird, setSelectedOptionThird] = useState(null);
  const [send, setSend] = useState({});
  const api = useBackend();
  const isFetching = useRef(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    isFetching.current = true;
    setIsLoading(true);
    api
      .suggestv2(props.appraisalid)
      .then((e) => {
        let result = e.data.matching;
        let defaultObject = { id: 0, value: 0, label: "(指定なし)" };
        result.unshift(defaultObject);
        setShop(result);
      })
      .finally(() => {
        isFetching.current = false;
      });
    setIsLoading(false);
  }, [props.appraisalid]);

  useEffect(() => {
    api.allshops().then((e) => {
      setAllShops(e.data);
    });
  }, []);

  useEffect(() => {
    const shopIds = new Set(shop.map((item) => item.id));
    const filteredShopList = shop.filter((item) => item.id !== 0);
    const updatedShopList = filteredShopList.map((item) => ({
      ...item,
      shopCategory: 1,
    }));

    const filteredShops = allShops.filter((item) => !shopIds.has(item.id));

    const updatedShopsList = filteredShops.map((item) => ({
      ...item,
      shopCategory: 2,
    }));

    const mergedShopList = [...updatedShopList, ...updatedShopsList];
    setShopList(mergedShopList);
  }, [shop, allShops]);

  useEffect(() => {
    setOptions(
      shopList?.map((option) => {
        const value = option.shopCategory;
        const groupTitle = value === 2 ? "全ての店舗" : "マッチング店舗";
        return {
          groupTitle,
          ...option,
        };
      })
    );
  }, [shopList]);

  useEffect(() => {
    setSelectedOptionFirst(
      options?.find((option) => option?.value === first_shop) || null
    );
    setSelectedOptionSecond(
      options?.find((option) => option?.value === second_shop) || null
    );
    setSelectedOptionThird(
      options?.find((option) => option?.value === third_shop) || null
    );
  }, [first_shop, second_shop, third_shop, options]);

  const onComplete = (e) => {
    api
      .shop_select(data?.appraisalid?.content, {
        ids: [first_shop, second_shop, third_shop],
      })
      .then((e) => {
        if (props.onComplete) props.onComplete();
        if (props.refreshData) props.refreshData();
      });
  };
  const onClickMatch = async () => {
    setIsLoading(true);
    try {
      const e = await api.suggestv2(data?.appraisalid?.content);
      const firstShopValue = e.data?.preferred[0]?.value;
      const secondShopValue = e.data?.preferred[1]?.value;
      const thirdShopValue = e.data?.preferred[2]?.value;

      setFirstShop(firstShopValue);
      setSecondShop(secondShopValue);
      setThirdShop(thirdShopValue);
    } finally {
      setIsLoading(false);
    }
  };

  const set = (d) => setSend((pre) => ({ ...pre, ...d }));
  return (
    <div className={`mordal_background`}>
      <div className="mordal_shop flex-center">
        <h2>買取店選択</h2>
        {isLoading || isFetching?.current ? (
          <div className="circular-progress">
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <>
            <div className="mordal_shop_dropdown_container">
              <div className="mordal_shop_dropdown">
                <Autocomplete
                  key={`first-shop-`}
                  id="shop_first"
                  name="shop_first"
                  size="small"
                  options={options}
                  groupBy={(option) => option.groupTitle}
                  getOptionLabel={(option) => option.label} // Keep this simple, return only the label
                  renderOption={(props, option) => (
                    <li {...props}>
                      {option?.stopped ? (
                        <>
                          <DoNotDisturbAltIcon className=" pr-1 " />{" "}
                          {/* Black stop icon */}
                          {option.label}
                        </>
                      ) : (
                        option.label
                      )}
                    </li>
                  )}
                  value={selectedOptionFirst}
                  onChange={(event, newValue) => {
                    setSelectedOptionFirst(newValue ? newValue : null);
                    setFirstShop(newValue ? newValue.value : null);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option && value && option.label === value.label
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="(指定なし)" />
                  )}
                  renderGroup={(params) => (
                    <li key={params.key}>
                      <GroupHeader>{params.group}</GroupHeader>
                      <GroupItems>{params.children}</GroupItems>
                    </li>
                  )}
                  noOptionsText="検索結果がありません"
                  freeSolo={true}
                  forcePopupIcon={true}
                />
              </div>
              <div className="mordal_shop_dropdown">
                {/* <DropDown
              label=""
              options={shopList}
              name="shop_second"
              id="shop_second"
              selected={second_shop}
              onChange={(e) => setSecondShop(parseInt(e.shop_second))}
            /> */}
                <Autocomplete
                  key={`second-shop-`}
                  id="shop_second"
                  name="shop_second"
                  size="small"
                  options={options}
                  groupBy={(option) => option.groupTitle}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <li {...props}>
                      {option?.stopped ? (
                        <>
                          <DoNotDisturbAltIcon className=" pr-1 " />
                          {option.label}
                        </>
                      ) : (
                        option.label
                      )}
                    </li>
                  )}
                  value={selectedOptionSecond}
                  onChange={(event, newValue) => {
                    setSelectedOptionSecond(newValue ? newValue : null);
                    setSecondShop(newValue ? newValue.value : null);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option && value && option.label === value.label
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="(指定なし)" />
                  )}
                  renderGroup={(params) => (
                    <li key={params.key}>
                      <GroupHeader>{params.group}</GroupHeader>
                      <GroupItems>{params.children}</GroupItems>
                    </li>
                  )}
                  noOptionsText="検索結果がありません"
                  freeSolo={true}
                  forcePopupIcon={true}
                />
              </div>
              <div className="mordal_shop_dropdown">
                {/* <DropDown
              label=""
              options={shop}
              name="shop_third"
              id="shop_third"
              selected={third_shop}
              onChange={(e) => setThirdShop(parseInt(e.shop_third))}
            /> */}
                <Autocomplete
                  id="shop_third"
                  key={`third-shop-`}
                  name="shop_third"
                  size="small"
                  options={options}
                  groupBy={(option) => option.groupTitle}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <li {...props}>
                      {option?.stopped ? (
                        <>
                          <DoNotDisturbAltIcon className=" pr-1 " />
                          {option.label}
                        </>
                      ) : (
                        option.label
                      )}
                    </li>
                  )}
                  value={selectedOptionThird}
                  onChange={(event, newValue) => {
                    setSelectedOptionThird(newValue ? newValue : null);
                    setThirdShop(newValue ? newValue.value : null);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option && value && option.label === value.label
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="(指定なし)" />
                  )}
                  renderGroup={(params) => (
                    <li key={params.key}>
                      <GroupHeader>{params.group}</GroupHeader>
                      <GroupItems>{params.children}</GroupItems>
                    </li>
                  )}
                  noOptionsText="検索結果がありません"
                  freeSolo={true}
                  forcePopupIcon={true}
                />
              </div>
            </div>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              className="mordal_match_dropdown_container"
            >
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Button
                  label="キャンセル"
                  color={"gray"}
                  on
                  onClick={props.onClose}
                ></Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Button
                  label="上書き自動マッチ"
                  color={"sub"}
                  on
                  onClick={onClickMatch}
                ></Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Button
                  label="決定"
                  color={"main"}
                  on
                  onClick={onComplete}
                  style={{ cursor: "pointer" }}
                ></Button>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </div>
  );
}
