import React, { useState, useEffect } from "react";
import {
  LoadingOutlined,
  CameraOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useBuyerStore } from "../../stores/buyers.store";

export const Uploader = (props) => {
  const [editStates, setEditStates] = useState(props.edit);
  const [previewUrl, setPreviewUrl] = useState(props.url || null);
  const [errorMessage, setErrorMessage] = useState("");
  const {clearImage,setClearImage} = useBuyerStore()

  const handleFileChange = (event) => {
    setEditStates(false);
    setErrorMessage(""); // Clear any previous error messages

    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      const fileExtension = uploadedFile.name.split(".").pop().toLowerCase();
      const allowedTypes = ["jpeg", "jpg", "png"];

      // Validate file type
      if (!allowedTypes.includes(fileExtension)) {
        setErrorMessage("ファイル形式はJPEGまたはPNGのみ許可されています。"); // "Only JPEG or PNG file types are allowed."
        return;
      }

      props.setFile(uploadedFile);
      setClearImage(false)
      props.setFileInfo({
        name: uploadedFile.name,
        size: uploadedFile.size,
        type: uploadedFile.type,
        extension: fileExtension,
      });
    }
  };

  const handleClearImage = () => {
    setEditStates(false);
    setPreviewUrl(null);
    setErrorMessage(""); // Clear error message when clearing the image
    props.setFile(null);
    props.setFileInfo(null);
    setClearImage(true)
  };

  useEffect(() => {
    let objectUrl = null;

    if (props.file && !editStates) {
      objectUrl = URL.createObjectURL(props.file);
      setPreviewUrl(objectUrl);
    } else if (props.url && editStates) {
      setPreviewUrl(props.url);
    }

    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [props.file, props.url, editStates]);

  return (
    <div className="w-full flex items-center gap-2">
      <div className="text-sm font-semibold min-h-40 flex items-center justify-center px-2 bg-[#587c94] border-b-[1px] border-white text-white min-w-[7rem]">
        {props.label}
      </div>
      <div className="relative border w-40 min-h-[150px] min-w-40  border-[#587c94] h-full flex items-center justify-center ">
        {!previewUrl && <CameraOutlined className="text-black text-2xl" />}
        {previewUrl && (
          <img
            src={previewUrl}
            alt="Uploaded"
            className="w-full h-full object-fit min-w-40 max-h-[150px]"
          />
        )}
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="max-h-40 absolute opacity-0 w-full h-full top-0 left-0 cursor-pointer"
        />
        {previewUrl && (
          <button
            type="button"
            onClick={handleClearImage}
            className="absolute top-1 right-1 bg-white rounded-full p-1 shadow-md hover:bg-gray-200"
          >
            <CloseOutlined className="text-black" />
          </button>
        )}
      </div>
      {errorMessage && (
        <div className="text-red-600 text-sm mt-1 w-full absolut right-0">
          {errorMessage}
        </div>
      )}
    </div>
  );
};
