import toast from "react-hot-toast";

const toastClass = " bg-white w-60 py-2 text-[#ff5b02] border border-[#ff5b02]  rounded-lg shadow-md flex items-center justify-center";

const setSuccessToast = (msg) => {
  return toast.custom(
    <div className={toastClass}>
      <span>{msg}</span>
    </div>
  );
};

const setCustomErrorToast = (error) => {
  return toast.custom(
    <div className={toastClass}>
      <span>{error}</span>
    </div>
  );
};

export { setSuccessToast, setCustomErrorToast };
