import { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import LogoImage from "../../../assets/images/logo_ctn.png";
import { generateBill } from "../../../utils/generateBill";
import { Button, Stack } from "@mui/material";

export default function DebitTransferPdf(props) {
  const printRef = useRef(null);
  const generateInvoicePDF = async () => {
    const element = printRef.current;
    if (element) {
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL("image/png");

      const pdf = new jsPDF();

      // Get the dimensions of the PDF page
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Get the original image dimensions
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // Calculate scaling factor
      const scale = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

      // Calculate new dimensions of the image
      const newWidth = imgWidth * scale;
      const newHeight = imgHeight * scale;

      // Center the image on the PDF page
      const x = (pdfWidth - newWidth) / 2;
      const y = (pdfHeight - newHeight) / 3;

      // Add the image to the PDF
      pdf.addImage(data, "PNG", x, y, newWidth, newHeight);
      pdf.save("invoice.pdf");
    }
  };

  return (
    <div className="notranslate" translate="no" style={{fontSize: "small"}}>
      <div>
        <div
          ref={printRef}
          style={{
            padding: "50px",
            fontSize: "small",
            backgroundColor: "#ffffff",
          }}
        >
          <div className="header-invoice-section">
            <div className="sub-invoice-section-1">
              <p>〒{props.data?.billingDataInvoiceDto?.zipCode} </p>
              <p>
                {props.data?.billingDataInvoiceDto?.billingPrefecture}{" "}
                {props.data?.billingDataInvoiceDto?.billingMuncipalities}{" "}
                {props.data?.billingDataInvoiceDto?.billingAddress}
              </p>
              <p>{props.data?.billingDataInvoiceDto?.companyName}</p>
              <p>{props.data?.billingDataInvoiceDto?.billingDepartment}</p>
              <p>{props.data?.billingDataInvoiceDto?.pic}</p>
            </div>
            <div className="sub-invoice-section-2">
              <p style={{ fontSize: "x-large", fontWeight: "bold" }}>
                請 求 書
              </p>
              <p>作成日：{props.data?.creationDate}</p>
              <p>登録番号：T3120001160456</p>

              <div
                className="sub-invoice-section-2-details"
                style={{ marginTop: "20px" }}
              >
                <img src={LogoImage} width={150} />
                <p>株式会社ＣＴＮ</p>
                <p>〒572-0086 大阪府寝屋川市松屋町18-9</p>
                <p>CasaAdvance2F</p>
                <p>TEL:072-800-7106 FAX:072-800-7107</p>
              </div>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <p>平素は格別のご高配を賜り、厚く御礼申し上げます。</p>
            <p>下記の通りご請求申上げます。</p>
          </div>
          <div className="invoice-container-table">
            <div className="total-amount-table">
              <table className="invoice-table" style={{ marginBottom: "40px" }}>
                <thead>
                  <tr>
                    <th className="invoice-table-header">合計金額</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="invoice-table-body">
                      {props.data?.TotalPlusTax >= 0
                        ? `¥${props.data?.TotalPlusTax}`
                        : `-¥${Math.abs(props.data?.TotalPlusTax)}`}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="transfer-table">
              <table className="invoice-table" style={{ marginBottom: "40px" }}>
                <thead>
                  <tr>
                    <th className="invoice-table-header">振替日</th>
                    <th className="invoice-table-body">
                       {props.data?.deadline || '-'}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <table className="invoice-table" style={{ marginBottom: "40px" }}>
            <thead>
              <tr>
                <th className="invoice-table-header">合計金額</th>
                <th className="invoice-table-header">10％対象</th>
                <th className="invoice-table-header">消費税（10％）</th>
                <th className="invoice-table-header">8％対象</th>
                <th className="invoice-table-header">消費税（8％）</th>
                <th className="invoice-table-header">非課税</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="invoice-table-body">
                  {props.data?.TotalPlusTax >= 0
                    ? `¥${props.data?.TotalPlusTax}`
                    : `-¥${Math.abs(props.data?.TotalPlusTax)}`}
                </td>
                <td className="invoice-table-body">
                  {props.data?.netTotal >= 0
                    ? `¥${props.data?.netTotal}`
                    : `-¥${Math.abs(props.data?.netTotal)}`}
                </td>
                <td className="invoice-table-body">
                  ¥
                  {props.data?.tenPercentTax > 0
                    ? props.data?.tenPercentTax
                    : 0}
                </td>
                <td className="invoice-table-body">¥0</td>
                <td className="invoice-table-body">
                  ¥
                  {props.data?.eightPercentTax > 0
                    ? props.data?.eightPercentTax
                    : 0}
                </td>
                <td className="invoice-table-body">
                  ¥{props.data?.taxExempt > 0 ? props.data?.taxExempt : 0}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="invoice-table">
            <thead>
              <tr>
                <th className="invoice-table-header">詳細</th>
                <th className="invoice-table-header">数量</th>
                <th className="invoice-table-header">単価</th>
                <th className="invoice-table-header">金額</th>
              </tr>
            </thead>
            <tbody>
              {generateBill(
                props.data?.assessmentEx,
                props.data?.assessmentNonEx,
                props.data?.rejectionLastMonthEx,
                props.data?.rejectionLastMonthNonEx,
                props.data?.referral,
                props.data?.introduction,
                props.data.nonExAmount,
                props.data.rejectionNonExAmount,
                props.data.exAmount,
                props.data.rejectionExAmount,
                props.data.japaneesMonth
              ).map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.description}</td>
                    <td>{item.volume}</td>
                    <td>¥{item.unitPrice}</td>
                    <td>{item.total}</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={2}></td>
                <td colSpan={1}>合計（税抜き）</td>
                <td>
                  {props.data?.netTotal >= 0
                    ? `¥${props.data?.netTotal}`
                    : `-¥${Math.abs(props.data?.netTotal)}`}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>備考</td>
                <td colSpan={1}>消費税（10％）</td>
                <td>
                  ¥
                  {props.data?.tenPercentTax > 0
                    ? props.data?.tenPercentTax
                    : 0}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  ※お振込手数料は、貴社にてご負担いただきますようお願申し上げます。
                </td>
                <td colSpan={1}>消費税（8％）</td>
                <td>
                  ¥
                  {props.data?.eightPercentTax > 0
                    ? props.data?.eightPercentTax
                    : 0}
                </td>
              </tr>
              <tr>
                <td colSpan={2}></td>
                <td colSpan={1}>非課税</td>
                <td>
                  ¥{props.data?.taxExempt > 0 ? props.data?.taxExempt : 0}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <Stack direction="row" justifyContent="flex-end">
        <Button variant="contained" onClick={generateInvoicePDF}>
          PDFの生成
        </Button>
      </Stack>
    </div>
  );
}
