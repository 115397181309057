import React, { useState, useEffect, useRef } from "react";
import { Checkbox } from "antd";
import { useDisabledStore } from "../../stores/disable.store";
import { hasNonNullValues } from "../adminPortalComponents/matchingCondition/functions/checkNullValues";
import { useSelectAllStore } from "../../stores/selectAllStore";
import { useSelectedTypeStore } from "../../stores/selectors.store";

export const ScrollSelector = (props) => {
  const { disabled, setDisabled } = useDisabledStore();
  const [selectAll, setSelectAll] = useState(false);
  const { setSelecteAllAction } = useSelectAllStore();
  const [checkedValues, setChekedValues] = useState({});
  const { setSelectors, selectors } = useSelectedTypeStore();

  // Refs to store checked values, initial values, and previous values
  const checkedValuesRef = useRef({});
  const initialCheckedValuesRef = useRef({});
  const prevSelectAllRef = useRef(false);

  const handleUncheckAll = () => {
    setSelectAll(false);

    // Uncheck all items
    const updatedCheckedValues = {};
    props.data.forEach((item) => {
      updatedCheckedValues[item.key] = null;
    });

    checkedValuesRef.current = updatedCheckedValues;
    props.setCheckedValues(updatedCheckedValues);
    handleUpdate(false);
  };

  // Update refs and state on props change
  useEffect(() => {
    initialCheckedValuesRef.current = props.checkedValues || {};
    checkedValuesRef.current = { ...initialCheckedValuesRef.current };
    if (Object.keys(checkedValues).length == 0) {
      setChekedValues(checkedValuesRef.current);
    }
    // Check if all items are selected based on initial values
    const allChecked = props.data.every(
      (item) => checkedValuesRef.current[item.key]
    );
    if (allChecked !== selectAll) {
      setSelectAll(allChecked);
      prevSelectAllRef.current = allChecked;
    }

    const newSelectAllAction = checkAllSelectors(allChecked, props.modalType);
    if (Object.keys(newSelectAllAction).length > 0) {
      setSelecteAllAction(newSelectAllAction);
    }
  }, [props.checkedValues, props.data, props.modalType, selectAll]);

  const checkAllSelectors = (allChecked, type) => {
    const newSelectAllAction = {};
    if (prevSelectAllRef.current !== allChecked) {
      switch (type) {
        case "okMakes":
          newSelectAllAction.okMakes = allChecked;
          break;
        case "okCarTypes":
          newSelectAllAction.okCarTypes = allChecked;
          break;
        case "okBodyTypes":
          newSelectAllAction.okBodyTypes = allChecked;
          break;
        case "ngCarTypes":
          newSelectAllAction.ngCarTypes = allChecked;
          break;
        case "ngBodyTypes":
          newSelectAllAction.ngBodyTypes = allChecked;
          break;
        case "ngMakes":
          newSelectAllAction.ngMakes = allChecked;
          break;
        default:
          break;
      }
      prevSelectAllRef.current = allChecked;
    }
    return newSelectAllAction;
  };

  useEffect(() => {
    if (Object.keys(checkedValues).length > 0) {
      handleUpdate();
    }
  }, [checkedValues]);

  const handleUpdate = (checked) => {
    if (
      props.type === "selector1" &&
      hasNonNullValues(checkedValuesRef.current)
    ) {

      setSelectors({
       ...selectors,
       selector1 : true
      });
    } else if (
      !hasNonNullValues(checkedValuesRef.current) &&
      props.type === "selector1"
    ) {
      
      setSelectors({
        ...selectors,
        selector1 : false
       });
    }

    if (
      props.type === "selector3" &&
      hasNonNullValues(checkedValuesRef.current)
    ) {
      setSelectors({
        ...selectors,
        selector3 : true
       });
    } else if (
      !hasNonNullValues(checkedValuesRef.current) &&
      props.type === "selector3"
    ) {
      setSelectors({
        ...selectors,
        selector3 : false
       });
    }

    const newSelectAllAction = checkAllSelectors(false, props.modalType);
    if (Object.keys(newSelectAllAction).length > 0) {
      setSelecteAllAction(newSelectAllAction);
    }

  };

 

  const handleCheckboxChange = (item, checked) => {
    const updatedCheckedValues = {
      ...checkedValuesRef.current,
      [item.key]: checked ? item.value : null,
    };

    checkedValuesRef.current = updatedCheckedValues;
    props.setCheckedValues(updatedCheckedValues);
    handleUpdate(checked);
  };

  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);

    const updatedCheckedValues = {};
    props.data.forEach((item) => {
      updatedCheckedValues[item.key] = checked ? item.value : null;
    });

    checkedValuesRef.current = updatedCheckedValues;
    props.setCheckedValues(updatedCheckedValues);
    handleUpdate(checked);
  };

  return (
    <>
      <h1
        className=" w-full text-left cursor-pointer "
        onClick={handleUncheckAll}
      >
        選択解除
      </h1>
      <div className="w-full border-[1px] border-blue-400 my-2">
        <div className="w-full p-4">
          <Checkbox
            checked={selectAll}
            onChange={handleSelectAllChange}
            disabled={props.disabled}
          >
            すべて選択
          </Checkbox>
        </div>
        <div className="w-full p-4 mb-2 h-[30rem] overflow-y-auto">
          {props.data.map((item) => (
            <div key={item.key} className="w-full">
              <Checkbox
                checked={
                  checkedValuesRef.current[item.key] !== null &&
                  checkedValuesRef.current[item.key] !== undefined
                }
                onChange={(e) => handleCheckboxChange(item, e.target.checked)}
                disabled={props.disabled}
              >
                {item.label}
              </Checkbox>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
