import { Link } from 'react-router-dom'
import logo from './logo-top.png'

export default function Logo(props) {
    const click = () => {
        if (window.location.pathname === "/console/appraisals") {
            window.location.reload()
        } else if (window.location.pathname === "/console/login") {
            return;
        } else {
            window.location.pathname = "/console/appraisals"
        }
    }
    return (
        <div onClick={click}>
        {props.center ? (
        <div className='logo_wrapper'>
            <div className='pad'></div>
            <img src={logo} className='logo'/>
            <div className='pad'></div>
        </div>
        ): (
            <div className="space_left small"> 
            <img src={logo} className='logo'/>
            </div>
        )}
        </div>
    )
}