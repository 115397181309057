import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function PDFInvoiceLoading() {

  return (
    <div>
      <div>
        <div
          style={{ padding: '50px', fontSize: 'small', backgroundColor: '#ffffff' }}>
          <div className="header-invoice-section">
            <div className="sub-invoice-section-1">
              <p> <Skeleton height={15} /> </p>
              <p> <Skeleton height={15} /> </p>
              <p> <Skeleton height={15} /> </p>
              <p> <Skeleton height={15} /> </p>
              <p> <Skeleton height={15} /> </p>
            </div>
            <div className="sub-invoice-section-2">
            <p> <Skeleton height={15} /> </p>
            <p> <Skeleton height={15} /> </p>
            <p> <Skeleton height={15} /> </p>

              <div className="sub-invoice-section-2-details" style={{ marginTop: '20px' }}>
              <p> <Skeleton height={15} /> </p>
              <p> <Skeleton height={15} /> </p>
              <p> <Skeleton height={15} /> </p>
              <p> <Skeleton height={15} /> </p>
              </div>
            </div>
          </div>
          <div style={{ marginBottom: '20px' }}>
          <p> <Skeleton height={15} /> </p>
          <p> <Skeleton height={15} /> </p>
          </div>
          <div className="invoice-container-table">
            <div className="total-amount-table">
              <table className="invoice-table" style={{ marginBottom: '40px' }}>
                <thead>
                  <tr>
                    <th className="invoice-table-header">  <p> <Skeleton height={15} /> </p></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="invoice-table-body">  <p> <Skeleton height={15} /> </p></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="transfer-table">
              <table className="invoice-table" style={{ marginBottom: '40px' }}>
                <thead>
                  <tr>
                    <th className="invoice-table-header">  <p> <Skeleton height={15} /> </p></th>
                    <th className="invoice-table-body">  <p> <Skeleton height={15} /> </p></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="invoice-table-header">  <p> <Skeleton height={15} /> </p></td>
                    <td className="invoice-table-body">
                       <p> <Skeleton height={15} /> </p>
                       <p> <Skeleton height={15} /> </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <table className="invoice-table" style={{ marginBottom: '40px' }}>
            <thead>
              <tr>
                <th className="invoice-table-header">  <p> <Skeleton height={15} /> </p></th>
                <th className="invoice-table-header">  <p> <Skeleton height={15} /> </p></th>
                <th className="invoice-table-header">  <p> <Skeleton height={15} /> </p></th>
                <th className="invoice-table-header">  <p> <Skeleton height={15} /> </p></th>
                <th className="invoice-table-header">  <p> <Skeleton height={15} /> </p></th>
                <th className="invoice-table-header">  <p> <Skeleton height={15} /> </p></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="invoice-table-body"> <p> <Skeleton height={15} /> </p></td>
                <td className="invoice-table-body"> <p> <Skeleton height={15} /> </p></td>
                <td className="invoice-table-body"> <p> <Skeleton height={15} /> </p></td>
                <td className="invoice-table-body"> <p> <Skeleton height={15} /> </p></td>
                <td className="invoice-table-body"> <p> <Skeleton height={15} /> </p></td>
                <td className="invoice-table-body"> <p> <Skeleton height={15} /> </p></td>
              </tr>
            </tbody>
          </table>
          <table className="invoice-table">
            <thead>
              <tr>
                <th className="invoice-table-header">  <p> <Skeleton height={15} /> </p></th>
                <th className="invoice-table-header">  <p> <Skeleton height={15} /> </p></th>
                <th className="invoice-table-header">  <p> <Skeleton height={15} /> </p></th>
                <th className="invoice-table-header">  <p> <Skeleton height={15} /> </p></th>
              </tr>
            </thead>
            <tbody>
                  <tr>
                    <td>  <p> <Skeleton height={15} /> </p></td>
                    <td>  <p> <Skeleton height={15} /> </p></td>
                    <td>  <p> <Skeleton height={15} /> </p></td>
                    <td>  <p> <Skeleton height={15} /> </p></td>
                  </tr>
                  <tr>
                    <td>  <p> <Skeleton height={15} /> </p></td>
                    <td>  <p> <Skeleton height={15} /> </p></td>
                    <td>  <p> <Skeleton height={15} /> </p></td>
                    <td>  <p> <Skeleton height={15} /> </p></td>
                  </tr>
                  <tr>
                    <td>  <p> <Skeleton height={15} /> </p></td>
                    <td>  <p> <Skeleton height={15} /> </p></td>
                    <td>  <p> <Skeleton height={15} /> </p></td>
                    <td>  <p> <Skeleton height={15} /> </p></td>
                  </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={2}></td>
                <td colSpan={1}>  <p> <Skeleton height={15} /> </p></td>
                <td>  <p> <Skeleton height={15} /> </p></td>
              </tr>
              <tr>
                <td colSpan={2}>  <p> <Skeleton height={15} /> </p></td>
                <td colSpan={1}>  <p> <Skeleton height={15} /> </p></td>
                <td>  <p> <Skeleton height={15} /> </p></td>
              </tr>
              <tr>
                <td colSpan={2}>
                <p> <Skeleton height={15} /> </p>
                </td>
                <td colSpan={1}>  <p> <Skeleton height={15} /> </p></td>
                <td>  <p> <Skeleton height={15} /> </p></td>
              </tr>
              <tr>
                <td colSpan={2}></td>
                <td colSpan={1}>  <p> <Skeleton height={15} /> </p></td>
                <td>  <p> <Skeleton height={15} /> </p></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}
