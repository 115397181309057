// import Button from "../../components/button";
import Text from "../../components/text";
// import Edit from "./Edit";
// import option from "../../data/choises.json"
import car from "../../data/car.json"
import { useBackend } from "../../hooks/useBackend";
// import report_data from "./report_data.json"
// import {useState} from 'react'
// import Shop from "./Shop";
// import MatchConfirm from "./MatchConfirm";

export default function Report(props) {
    // const d = car
    const car_maker = (maker) => car.car.filter(e => e.value === maker)[0]
    const car_type = (maker, type) => car_maker(maker).type.filter(e => e.value === type)[0]
    const model_year = (year) => car.year.filter(e => e.value === year)[0]
    const distance = (distance) => car.distance.filter(e => e.value === distance)[0]
    // const desire = (desire_date) => choise_option.desire_date.filter(e => e.value === desire_date)[0]
    const data = props.data
    // const detail = props.data
    return (
        <>
        <div className="card report_card" key={data?.shop?.id?.content}>
            <div className="each_column report_main_info_area">
                <div className="store_name">
                    <Text value={`${data?.shop?.shopName}`} big />
                </div>
                <div className="flex report_total_info_area">
                    <div className="each_report_summary">
                        <Text value="合計" label normal />
                        <div className="flex">
                            <Text value={`${data?.appraisals?.length}`} header/>
                            <Text value={`件`} />
                        </div>
                    </div>
                    <div className="each_report_summary">
                        <Text value="SEO" label normal/>
                        <div className="flex">
                            <Text value={`${data?.appraisals?.filter(e => e.type === "SEO").length}`} header/>
                            <Text value={`件`} />
                        </div>
                    </div>
                    <div className="each_report_summary">
                        <Text value="AD" label normal/>
                        <div className="flex">
                            <Text value={`${data?.appraisals?.filter(e => e.type === "Paid").length}`} header/>
                            <Text value={`件`} />
                        </div>
                    </div>
                    <div className="each_report_summary">
                        <Text value="AD2" label normal/>
                        <div className="flex">
                            <Text value={`${data?.appraisals?.filter(e => e.type === "Paid2").length}`} header/>
                            <Text value={`件`} />
                        </div>
                    </div>
                    <div className="each_report_summary">
                        <Text value="AD3" label normal/>
                        <div className="flex">
                            <Text value={`${data?.appraisals?.filter(e => e.type === "Paid3").length}`} header/>
                            <Text value={`件`} />
                        </div>
                    </div>
                    <div className="each_report_summary">
                        <Text value="AD4" label normal/>
                        <div className="flex">
                            <Text value={`${data?.appraisals?.filter(e => e.type === "Paid4").length}`} header/>
                            <Text value={`件`} />
                        </div>
                    </div>
                    <div className="each_report_summary">
                        <Text value="架電" label normal/>
                        <div className="flex">
                            <Text value={`${data?.appraisals?.filter(e => e.type === "Phone").length}`} header/>
                            <Text value={`件`} />
                        </div>
                    </div>
                    {/* <div>
                        <Text value="総額" label/>
                        <Text value={`${data.total_appraisal}円`} header/>
                    </div> */}
                </div>
            </div>
            <div className="each_column report_store_info_area">
                <div className="report_inner">
                    <Text value="メールアドレス" label/>
                    <Text value={`${data?.shop?.emails?.emails.map(e => e?.content).join("、")}`} />
                </div>
                <div className="report_inner">
                    <Text value="エリア" label/>
                    <Text value={`${data?.shop?.area?.districtList[0]?.prefecture}${data?.shop?.area?.districtList[0]?.municipalities}${data?.shop?.area?.districtList?.length > 1 ? "ほか" : ""}`} />
                </div>
            </div>
            <div className="each_column border no_pad"></div>
            {/* <div className="each_column car-info-area">
                <div className="flex ">
                    <div>
                        <Text value="対象車種" label/>
                        <Text value={`${car_type(data.car_maker, data.car_type).label}`}  />
                    </div>
                    <div>
                        <Text value="希望年式" label/>
                        <Text value={`${model_year(data.car_model_year).label}`} />
                    </div>
                    <div>
                        <Text value="買取価格" label/>
                        <Text value={`${data.purchase_price_low}万円〜${data.purchase_price_high}万円`}/>
                    </div>
                </div>
                <div className="flex ">
                    <div>
                        <Text value="対象ボディタイプ" label/>
                        <Text value={`${car_type(data.car_maker, data.car_type).label}`}  />
                    </div>
                    <div>
                        <Text value="希望走行距離" label/>
                        <Text value={`${distance(data.car_traveled_distance).label}`} />
                    </div>
                    <div>
                        <Text value="廃車・事故車" label/>
                        <Text value={`${data.accident}`} />
                    </div>
                </div>
            </div> */}
            <div className="each_column report_car_info_area flex">
                <div className="vflex">
                    <div>
                        <Text value="専門" header/>
                    </div>
                    <div className="flex">
                        <div className="vflex ">
                            <div className="report_inner">
                                <Text value="車種" label/>
                                <Text value={`${data.shop?.speciality?.carTypeSpeciality?.contents.map(e => e.content).filter(e => e.length > 0)?.join("、").length <= 0 ? "なし" :  new Array([...new Set(data.shop?.speciality?.carTypeSpeciality?.contents?.map(e => e.content).filter(e => e.length > 0))])?.join('、')}`}  />
                            </div>
                            <div className="report_inner">
                                <Text value="メーカー" label/>
                                <Text value={`${data.shop?.speciality?.makerSpeciality?.contents.map(e => e.content).filter(e => e.length > 0)?.join("、").length <= 0 ? "なし" :  new Array([...new Set(data.shop?.speciality?.makerSpeciality?.contents?.map(e => e.content).filter(e => e.length > 0))])?.join("、")}`}  />
                            </div>
                        </div>
                        <div className="vflex ">
                            <div className="report_inner">
                                <Text value="ボディタイプ" label/>
                                <Text value={`${data.shop?.speciality?.bodyTypeSpeciality?.contents.map(e => e.content).filter(e => e.length > 0)?.join("、").length <= 0 ? "なし" :  new Array([...new Set(data.shop?.speciality?.bodyTypeSpeciality?.contents?.map(e => e.content).filter(e => e.length > 0))])?.join("、")}`}  />
                            </div>
                            <div className="report_inner">
                                <Text value="国産・輸入" label/>
                                <Text value={`${data.shop?.speciality?.domesticOrImport == "BOTH" ? "両方"
                                                    : data.shop?.speciality?.domesticOrImport === "DOMESTIC" ? "国産" 
                                                    : data.shop?.speciality?.domesticOrImport === "IMPORT" ? "輸入" : "-"}`}  />
                            </div>
                        </div>
                        <div className="vflex ">
                            <div className="report_inner">
                                <Text value="不動車" label/>
                                <Text value={`${data.shop?.speciality?.handleNotMove === null ? "-" : data.shop?.speciality?.handleNotMove}`}  />
                            </div>
                            <div className="report_inner">
                                <Text value="専門外" label/>
                                <Text value={`${data.shop?.speciality?.handleNotSpeciality === null ? "-" : data.shop?.speciality?.handleNotSpeciality}`}  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="each_column border no_pad"></div>
            <div className="each_column report_car_info_area flex">
                <div className="vflex">
                    <div>
                    <Text value="一般" header/>
                    </div>
                    <div className="flex">
                        <div className="vflex ">
                            <div className="report_inner">
                                <Text value="国産・輸入" label/>
                                <Text value={`${data.shop?.generality?.domesticOrImport === "BOTH" ? "両方"
                                : data.shop?.generality?.domesticOrImport === "DOMESTIC" ? "国産"
                                : data.shop?.generality?.domesticOrImport === "IMPORT" ? "輸入" : "-"}`} />
                            </div>
                            <div className="report_inner">
                                <Text value="不動車買取" label/>
                                <Text value={`${data.shop?.generality?.notMove}`} />
                            </div>
                        </div>
                        <div className="vflex ">
                            <div className="report_inner">
                                <Text value="OKメーカー" label/>
                                <Text value={`${data.shop?.generality?.wishMaker?.contents.map(e => e.content).filter(e => e.length > 0)?.join("、").length <= 0 ? "なし" :  new Array([...new Set(data.shop?.generality?.wishMaker?.contents.map(e => e.content).filter(e => e.length > 0))])?.join("、")}`} />
                            </div>
                            <div className="report_inner">
                                <Text value="NGメーカー" label/>
                                <Text value={`${data.shop?.generality?.ngMaker?.content.map(e => e.content).filter(e => e.length > 0)?.join("、").length <= 0 ? "なし" : new Array([...new Set(data.shop?.generality?.ngMaker?.content.map(e => e.content).filter(e => e.length > 0))]).join("、")}`} />
                            </div>
                        </div>
                        <div className="vflex ">
                            <div className="report_inner">
                                <Text value="OKボディタイプ" label/>
                                <Text value={`${data.shop?.generality?.wishBodyType?.content.map(e => e.content).filter(e => e.length > 0)?.join("、").length <= 0 ? "なし" : new Array([...new Set(data.shop?.generality?.wishBodyType?.content.map(e => e.content).filter(e => e.length > 0))])?.join("、")}`} />
                            </div>
                            <div className="report_inner">
                                <Text value="NGボディタイプ" label/>
                                <Text value={`${data.shop?.generality?.NGBodyType?.content.map(e => e.content).filter(e => e.length > 0)?.join("、").length <= 0 ? "なし" :  new Array([...new Set(data.shop?.generality?.NGBodyType?.content.map(e => e.content).filter(e => e.length > 0))])?.join("、")}`} />
                            </div>
                        </div>
                        <div className="vflex ">
                                <div className="report_inner">
                                    <Text value="OK年式" label/>
                                    <Text value={`${data.shop?.generality?.yearWishesFrom?.expression ?? '-'}~${data.shop?.generality?.yearWishesTo?.expression ?? '-'}`} />
                                </div>
                                <div className="report_inner">
                                    <Text value="NG年式" label/>
                                    <Text value={`${data.shop?.generality?.yearNGFrom?.expression ?? '-'}~${data.shop?.generality?.yearNGTo?.expression ?? '-'}`} />
                                </div>
                            </div>
                            <div className="vflex ">
                                <div className="report_inner">
                                    <Text value="OK走行距離" label/>
                                    <Text value={`${data.shop?.generality?.distanceWishFrom?.expression ?? '-'}~${data.shop?.generality?.distanceWishTo?.expression ?? '-'}`} />
                                </div>
                                <div className="report_inner">
                                    <Text value="NG走行距離" label/>
                                    <Text value={`${data.shop?.generality?.distanceNGFrom?.expression ?? '-'}~${data.shop?.generality?.distanceNGTo?.expression ?? '-'}`} />
                                </div>
                            </div>
                        <div className="vflex ">
                            <div className="report_inner">
                                <Text value="買取店規模" label/>
                                <Text value={`${
                                    data.shop?.generality?.rank?.rank === 1 ? "小"
                                : data.shop?.generality?.rank?.rank === 2 ? "中"
                                : data.shop?.generality?.rank?.rank === 3 ? "大"
                                : ""}`}/>
                            </div>
                            <div className="report_inner">
                                <Text value="上限数" label/>
                                <Text value={`${data.shop?.generality?.ceilMatchCount > 10000000 ? "なし" : data.shop?.generality?.ceilMatchCount}`} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}