import axios from 'axios';
import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys.constant';

const axiosClient = axios.create({});

axiosClient.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
};

//All request will wait 6 seconds before timeout
axiosClient.defaults.timeout = 30000;

axiosClient.defaults.withCredentials = true;

axiosClient.interceptors.request.use(
  async (config) => {
      try {
          const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
          if (token) {
              config.headers.Authorization = `Bearer ${token}`;
          }
      } catch (error) {
          console.error('Error while setting up Authorization header:', error);
      }
      return config;
  },
  (error) => {
      return Promise.reject(error);
  }
);
axiosClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        const currentPath = window.location.pathname;
        if (!currentPath.startsWith('/console')) {
          window.location.href = '/console/login';
        } else {
          window.location.href = '/console/login';
        }
      }
      return Promise.reject(error);
    }
  );



export default axiosClient;
