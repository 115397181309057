import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const auditApi = createApi({
  reducerPath: "auditApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_ENDPOINT_V2}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Audit"],
  endpoints: (builder) => ({
    getAudits: builder.query({
      query: ({ pageable, user }) => ({
        url: `/audit/all`,
        method: "GET",
        params: {
          page: pageable.page,
          size: pageable.size,
          sort: pageable.sort.join(','),
          ...(user && { user }) 
        },
      }),
      providesTags: ["Audit"],
    }),
    getAuditById: builder.query({
      query: (id) => ({
        url: "/audit",
        method: "GET",
        params: { id },
      }),
      providesTags: ["Audit"],
    }),
    getAdminUsers: builder.query({
      query: () => ({
        url: "/audit/users",
        method: "GET",
      }),
      providesTags: ["Audit"],
    }),
  }),
});

export const { 
  useGetAuditsQuery, 
  useGetAuditByIdQuery,
  useGetAdminUsersQuery 
} = auditApi;