import { useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TableLoadingSkeleton } from "../../components";
import { useDeleteNoticeMutation } from "../../app/services/notices.api";
import moment from "moment";

export default function NoticesTable(props) {
  const navigate = useNavigate();
  const [deleteNoticeFunc] = useDeleteNoticeMutation();
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleDeleteConfirmation = (id) => {
    setDeleteId(id);
    setIsOpenDelete(true);
  };

  const handleDelete = () => {
    deleteNoticeFunc(deleteId);
    setIsOpenDelete(false);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "created",
        enableHiding: false,
        header: "created",
        maxSize: 70,
        Cell: ({ row }) => {
          return (
            <span>
              {moment(row.original.created).format("YYYY-MM-DD HH:mm")}
            </span>
          );
        },
      },
      {
        accessorKey: "title",
        enableSorting: false,
        enableHiding: false,
        header: "title",
        maxSize: 100,
      },
      {
        accessorKey: "content",
        enableSorting: false,
        enableHiding: false,
        header: "content",
        maxSize: 600,
        Cell: ({ row }) => {
          return (
            <div dangerouslySetInnerHTML={{ __html: row.original.content }} />
          );
        },
      },
      {
        accessorKey: "actionButtons",
        enableSorting: false,
        enableHiding: false,
        header: "actionButtons",
        Cell: ({ row }) => {
          return (
            <Stack direction={"row"} spacing={2} justifyContent="flex-end">
              <Button
                variant="contained"
                className="notice-edit"
                onClick={() => {
                  navigate(`/console/notice/edit`, {
                    state: {
                      previousPage: "/console/dashboard",
                      previousPageSubLevel: "/console/appraisals",
                      notice: row.original,
                      type: "EDIT",
                    },
                  });
                }}
              >
                詳細·編集
              </Button>
              <Button
                className="notice-delete"
                variant="contained"
                onClick={() => handleDeleteConfirmation(row.original.id)}
              >
                削除
              </Button>
            </Stack>
          );
        },
      },
    ],
    [props.data]
  );

  const table = useMaterialReactTable({
    columns,
    data: props.data ? props.data?.content : [],
    enableExpanding: false,
    enableRowSelection: false,
    enableColumnOrdering: false,
    enableGlobalFilter: false,
    enableTopToolbar: false,
    enableSorting: false,
    enableFilters: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    manualPagination: false,
    localization: {
      rowsPerPage: "ページあたりの行数",
      of: "の",
    },
    initialState: {
      columnPinning: { right: ["actionButtons"] },
    },
    muiTableHeadRowProps: {
      style: { display: "none" },
    },
    muiBottomToolbarProps: {
      style: { display: "none" },
    },
  });

  return (
    <div className="material-react-table-body">
      {props.isLoading ? (
        <TableLoadingSkeleton />
      ) : (
        <MaterialReactTable table={table} />
      )}
      {isOpenDelete && (
        <div className="w-screen h-screen fixed bg-transparent top-0 left-0 z-[80]">
          <div className="w-full h-full flex items-center justify-center">
            <div className="bg-white flex flex-col items-center justify-center gap-4 w-4/12 h-[20vh] p-4 shadow-sm shadow-[#f25f2a] border-[#f25f2a] border">
              <h1>本当に削除しますか？</h1>
              <div className="flex items-center gap-4">
                <Button variant="contained" onClick={handleDelete}>
                  はい
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setIsOpenDelete(false)}
                >
                  いいえ
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
