import { Button } from "@mui/material";
import React from "react";
import { useUpdateDepositedStatusMutation } from "../../app/services/invoices.api";

export default function DepositModal(props) {
  const [updateDepositedStatus] = useUpdateDepositedStatusMutation();

  const onClickHandler = () => {
    updateDepositedStatus({ year: props.year, month: props.month, shopId: props.shopId })
      .then(() => {
        updateDepositedStatus().unwrap().then(()=>{
          props.handleClose && props.handleClose();
        }).catch((error)=>{
          console.log(error)
        }).finally(()=>{
          props.handleClose && props.handleClose();
        })
      })
      .catch((error) => {
        console.log(error)
      });
  };

  return (
    <div>
      <p style={{ marginBottom: "30px" }}>
        {props.status === "BEFORE_DEPOSIT" ? "入金済みに変更しますか？" : "入金前に変更しますか？"}
      </p>
      <div>
        <Button variant="contained" onClick={onClickHandler}>
          はい
        </Button>
      </div>
    </div>
  );
}
