import { Form as AntdForm, FormProps } from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect } from "react";

export const Form = ({
  children,
  onFinish,
  onFinishFailed,
  onReset,
  initialValues,
  className,
  form,
  resetForm = false,
  ...rest
}) => {
  const [myform] = useForm();

  useEffect(() => {
    if (initialValues) {
      myform.setFieldsValue(initialValues);
    }
  }, [initialValues, myform]);
  return (
    <AntdForm
      form={form ? form : myform}
      onFinish={async (values) => {
        if (onFinish) {
          await onFinish(values);
        }
        if (resetForm) {
          form.resetFields();
        }
      }}
      onFinishFailed={(errorInfo) => {
        if (onFinishFailed) {
          onFinishFailed(errorInfo);
        }
        if (resetForm) {
          form.resetFields();
        }
      }}
      className={className}
      initialValues={initialValues}
      {...rest}
    >
      {children}
    </AntdForm>
  );
};
