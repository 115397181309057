import React, { useEffect, useRef, useState } from "react";
import AppraisalCard from "./appraisal-card";
import {
  useLazyGetRejectAppraisalsQuery,
  useUpdateAppraisalStatusMutation,
  useGetRejectAppraisalsByIdQuery,
} from "../../app/services/reject.api";
import { ModalComponent } from "../../components";
import AssessmentRequestDetailsLayout from "../assessment-request-details";
import AssessmentDetailsLayout from "./appraisal_list";

export default function RejectedApplications() {
  const [getRejectAppraisals, { data: appraisalData, isLoading }] =
    useLazyGetRejectAppraisalsQuery();
  const [updateAppraisalStatus] = useUpdateAppraisalStatusMutation();
  const [selectedAppraisalId, setSelectedAppraisalId] = useState(null);
  const { data: detailedAppraisalData } = useGetRejectAppraisalsByIdQuery(
    selectedAppraisalId,
    {
      skip: !selectedAppraisalId,
    }
  );

  const [activeFilter, setActiveFilter] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedAppraisal = useRef(null);
  let aID;

  const filterButtons = [
    {
      label: "却下申請中",
      value: "submit_an_application",
      activeClass: "bg-[#8E8E93] text-white border-[#8E8E93]",
      inactiveClass: "border-[#8E8E93] text-[#8E8E93]",
    },
    {
      label: "却下申請承認",
      value: "recognition_applied",
      activeClass: "bg-[rgb(17,41,82)] text-white border-[rgb(17,41,82)]",
      inactiveClass: "border-[rgb(17,41,82)] text-[rgb(17,41,82)]",
    },
    {
      label: "却下申請棄却",
      value: "recognition_not_accepted",
      activeClass: "bg-[#f25f2a] text-white border-[#f25f2a]",
      inactiveClass: "border-[#f25f2a] text-[#f25f2a]",
    },
  ];

  useEffect(() => {
    getRejectAppraisals(activeFilter);
  }, [getRejectAppraisals, activeFilter]);

  useEffect(() => {
    if (detailedAppraisalData) {
      selectedAppraisal.current = detailedAppraisalData;
      setIsModalOpen(true);
    }
  }, [detailedAppraisalData]);

  const handleUpdateStatus = async (appraisalId, shopId, status) => {
    if (status === "assessment_confirmation") {
      setSelectedAppraisalId(appraisalId);
    } else {
      await updateAppraisalStatus({ appraisalId, shopId, status });
    }
  };

  const handleFilterClick = (filterValue) => {
    setActiveFilter(activeFilter === filterValue ? null : filterValue);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    selectedAppraisal.current = null;
    setSelectedAppraisalId(null);
  };
  if (isLoading) {
    return <p>読み込み中...</p>;
  }

  return (
    <div className="p-4">
      <div className="mb-6 flex flex-wrap gap-4 max-sm:justify-center">
        {filterButtons.map((button) => (
          <button
            key={button.value}
            onClick={() => handleFilterClick(button.value)}
            className={`px-4 py-2 rounded-md font-semibold border-2 transition-all duration-200 ${
              activeFilter === button.value
                ? button.activeClass
                : button.inactiveClass
            }`}
          >
            {button.label}
          </button>
        ))}
      </div>

      <div className="grid gap-4 ">
        {appraisalData?.map((appraisal) => (
          <AppraisalCard
            key={`${appraisal.assessed.id.appraisalid}-${appraisal.assessed.id.shopid}`}
            appraisal={appraisal}
            onUpdateStatus={handleUpdateStatus}
          />
        ))}
      </div>

      <ModalComponent isOpen={isModalOpen} handleClose={handleCloseModal}>
        <AssessmentDetailsLayout
          handleClose={handleCloseModal}
          data={selectedAppraisal.current}
        />
      </ModalComponent>
    </div>
  );
}
