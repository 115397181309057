import { useState } from "react"

export default function TextArea(props) {
    const [selected, setSelected] = useState(props.value)
    const onChange = (e) => {
      setSelected(e.target.value)
      let d = {}
      d[props.id] = e.target.value
      props.onChange(d)
    }
    return (
        <div className={`input-norm ${props.sparse ? "sparse":props.narrow ? "narrow" : ""}`}>
        <span className="label font_input_label">{props.label}</span>
        {/* Have to check text area */}
        <textarea className="input font_input_content" onChange={onChange} value={selected}></textarea>
    </div>
    )
}