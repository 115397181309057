import React from "react";
import { Form } from "antd";

const { Item } = Form;

export const FormItem = ({
  colon = false,
  fullWidth = false,
  className,
  ...props
}) => {
  
  return (
    <Item
      className={`${fullWidth ? " flex-1 p-0 m-0" : ""} ${className}`}
      colon={colon}
      dependencies={props.dependencies}
      initialValue={props.initialValue}
      required={props.required}
      rules={props.rules}
      {...props}
    >
      {props.children}
    </Item>
  );
};
