import React, { useEffect, useState } from "react";
import { DatePicker, ConfigProvider } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import jaJP from "antd/es/locale/ja_JP";

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

export const DateTimePicker = (props) => {
  const { time, setTime, initialValue } = props;
  const [formattedDate, setFormattedDate] = useState(
    initialValue ? dayjs(initialValue) : null
  );

  useEffect(() => {
    if (initialValue) {
      setFormattedDate(dayjs(initialValue));
    }
  }, [initialValue]);

  const handleChange = (value) => {
    if (value) {
      setFormattedDate(value);
      setTime(value.format("YYYY-MM-DD HH:mm:ss"));
    } else {
      setFormattedDate(null);
      setTime(null);
    }
  };

  return (
    <ConfigProvider
      locale={jaJP}
      theme={{
        components: {
          DatePicker: {
            colorBgContainer: "#fff9c5",
          },
        },
      }}
    >
      <DatePicker
        format="YYYY-MM-DD"
        value={formattedDate}
        minDate={props.minDate ? dayjs(props.minDate) : null}
        onChange={handleChange}
        placeholder=""
        className="py-2 w-full"
        suffixIcon={<h1></h1>}
      />
    </ConfigProvider>
  );
};
