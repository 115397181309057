import React from "react";
import { Container, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import Logout from "../../features/auth/logout";
import Button from "../button";
import Logo from "../Logo";

function MainWrapper(props) {
  return (
    <Container maxWidth="lg xl">
      <div className="header-bar">
        <div className="logo-wrapper">
          <Logo />
        </div>
        <div className="logout-wrapper">
          <Stack direction="row" spacing={2} sx={{}} alignItems="center">
            <Stack direction={{ lg: "column", xl: "row" }} spacing={1} sx={{}}>
              <Link to="/console/register">
                <Button label="新規ユーザー登録" color={"sub"} on />
              </Link>
            </Stack>
            <Stack direction={{ lg: "row", xl: "row" }} spacing={1} sx={{}}>
              <Link to="/console/password-change">
                <Button label="パスワード変更" color={"sub"} on />
              </Link>
            </Stack>
            <Stack direction={{ lg: "row", xl: "row" }} spacing={1}>
              <Logout />
            </Stack>
          </Stack>
        </div>
      </div>
      <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>
        {props.children}
      </div>
    </Container>
  );
}

export default MainWrapper;
