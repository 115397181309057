import { useEffect, useState } from "react";
import { useAppStore } from "../stores/app.store";
import {
  getShopById,
  getShopConditionById,
} from "../app/services/shop.service";
import { setCustomErrorToast } from "../utils/notifications";

export const useFetchShopConditonsByIdHook = (id, type,allowToFetch) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [shopCondtions, setShopConditions] = useState();
  const { editRegisterId } = useAppStore();

  const fetchShopCondtionById = async () => {
    try {
      setLoading(true);
      const response = await getShopConditionById(
        editRegisterId || id,
        type || ""
      );
      setShopConditions(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setCustomErrorToast("問題が発生しました");
      setError(error);
      setLoading(true);
    }
  };
  useEffect(() => {
    if ((editRegisterId || id) && allowToFetch) {
      fetchShopCondtionById();
    }
  }, [editRegisterId, id,type,allowToFetch]);

  return { shopCondtions, error, loading };
};
