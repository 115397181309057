import InvoicePdf from "./invoice-pdf";
import DebitTransferPdf from "./debit-transfer-pdf";
import PDFInvoiceLoading from "../../../components/loading-screens/pdf-invoice-loading";
import { useGenerateInvoiceBillQuery } from "../../../app/services/invoices.api";

function PdfGeneration(props) {
  const { data, isLoading } = useGenerateInvoiceBillQuery({
    year: props.year,
    month: props.month,
    shopId: props.shopId,
  });

  if (isLoading) {
    return <PDFInvoiceLoading />;
  }

  return (
    <div>
      {data &&
        data.billingDataInvoiceDto &&
        data.billingDataInvoiceDto?.paymentType === "BILLING" && (
          <InvoicePdf data={data} />
        )}
      {data &&
        data.billingDataInvoiceDto &&
        data.billingDataInvoiceDto?.paymentType !== "BILLING" && (
          <DebitTransferPdf data={data} />
        )}
      {data && !data.billingDataInvoiceDto && <p>PDFは生成されませんでした</p>}
      {!data && <p>PDFは生成されませんでした</p>}
    </div>
  );
}

export default PdfGeneration;
