import React, { useRef } from "react";
import { Button, Stack } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStore } from "../../stores/app.store";

export default function NavigatePage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isTabPage, setMainTabIndex } = useAppStore();
  // const urlParams = new URLSearchParams(window.location.search);
  // const tab = urlParams.get("tab");

  const navigateToPreviousPage = () => {
    if (isTabPage) {
      setMainTabIndex(true);
    } else if (state.previousPage) {
      navigate(state.previousPage, {
        state: {
          previousPage: state.previousPageSubLevel,
        },
      });
    }
  };

  const navigateToMainPage = () => {
    setMainTabIndex(true);
  }

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      {state && (state.previousPage || state.previousPageSubLevel) && (
        <div className="ctn-wrapper" style={{ marginBottom: "20px" }}>
          <Button variant="contained" onClick={navigateToPreviousPage}>
            <ArrowBackIcon />
          </Button>
        </div>
      )}

      {/* {(tab == "6" || tab == "5") && (
        <div className="ctn-wrapper" style={{ marginBottom: "20px" }}>
          <Button variant="contained" onClick={navigateToMainPage}>
            <ArrowBackIcon />
          </Button>
        </div>
      )} */}
    </Stack>
  );
}
