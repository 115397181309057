import React, { useState } from "react";
import Button from "./button";

export default function Radio(props) {
    const option = props.option
    const [selected, setSelected] = useState({value : props.selected, id : props.id + props.selected})
    const isDisabled = props.data?.status === "cancelled"
    const onSelect = (e) => {
      if (isDisabled) return 
      console.debug({value : e.target.value, id : e.target.id})
      setSelected({value : e.target.value, id : e.target.id})
      let d = {}
      d[e.target.dataset.parent] = e.target.value
      props.onChange(d)
    }

    return (
      <div className="vflex">
        <span className="label font_input_label">{props.label}</span>
        <div className={`space_y_xs ${props.horizontal ? "flex" : "vflex"}`}>
          {option.map((e, index) => (
            <React.Fragment key={index}>
              <input
                name={props.label}
                id={e.id + e.value}
                className={`radio_parts_${e.color}`}
                type="radio"
                data-parent={e.id}
                value={e.value}
                onChange={onSelect}
                checked={selected?.id === e.id + e.value}
                disabled={isDisabled}
              />
              <label htmlFor={e.id + e.value} className={`input font_button_label ${selected?.id === e.id + e.value ? "checked" : ""} ${props.narrow ? "narrow" : ""} ${isDisabled ? "disabled" : ""}`}>
                {e.label}
              </label>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
}