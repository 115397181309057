import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button } from "@mui/material";
import { DatePicker, ConfigProvider } from "antd";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import "dayjs/locale/ja";
import { useUpdateApproveDateMutation } from "../../app/services/reject.api";

dayjs.locale('ja');

const AppraisalCard = ({ appraisal, onUpdateStatus }) => {
  const [updateApproveDate] = useUpdateApproveDateMutation();
  const {
    companyName,
    shopName,
    assessed: {
      id: { appraisalid, shopid },
      status,
      evidenceImage,
      reasonForRejection,
      applicationDate,
      approvalDate,
      dismissalDate,
      rejectionApprovalDate,
    },
  } = appraisal;

  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [actionType, setActionType] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [approveDate, setApproveDate] = useState(approvalDate);

  const convertUTCToLocal = (utcDateString) => {
    if (!utcDateString) return null;
    // Parse the UTC date string
    const date = new Date(utcDateString);
    // Create dayjs object and adjust for timezone offset
    return dayjs(date);
  };

  // Convert local time to UTC format for backend
  const convertLocalToUTC = (localDate) => {
    if (!localDate) return null;
    const date = localDate.toDate();
    return date.toISOString();
  };

  const handleConfirmation = (type) => {
    
    setActionType(type);
    setIsOpenDelete(true);
  };

  useEffect(() => {
    if (rejectionApprovalDate) {
      const localDate = convertUTCToLocal(rejectionApprovalDate);
      setSelectedDate(localDate);
    }
  }, [rejectionApprovalDate, approveDate]);

  const handleDateChange = async (date) => {
    setSelectedDate(date);
  };

  const handleActionConfirm = async () => {
    if (actionType === "approval") {
      const dateToUse = selectedDate || dayjs();
      const utcDate = convertLocalToUTC(dateToUse);
      
      try {
        await updateApproveDate({
          appraisalId: appraisalid,
          shopId: shopid,
          approveDate: selectedDate ? selectedDate : utcDate,
        }).unwrap;
        const appDate = selectedDate ? selectedDate : utcDate;
        console.log("appDate", appDate);
        await onUpdateStatus(appraisalid, shopid, "recognition_applied", appDate);
        setApproveDate(selectedDate);
      } catch (error) {
        console.error("Error during approval process:", error);
      }
    } else if (actionType === "rejection") {
      await onUpdateStatus(appraisalid, shopid, "recognition_not_accepted");
    }
    setIsOpenDelete(false);
  };

  const handleActionCancel = () => {
    setIsOpenDelete(false);
  };

  const formatDisplayDate = (dateString) => {
    if (!dateString) return "--";
    return dayjs(dateString).format("YYYY-MM-DD");
  };

  return (
    <ConfigProvider>
      <div className="border border-gray-300 p-4 mb-4 rounded-lg">
        {/* Previous JSX remains the same */}
        <div className="flex justify-between">
          <div className="justify-start">
            <p className="text-lg text-gray-600 font-semibold mb-2">
              買取店名 : {shopName}
            </p>
          </div>
          <div className="justify-end">
            <p className="text-gray-600 font-semibold mb-2">
              承認ステータス:
              {status === "recognition_applied"
                ? " 承認済み"
                : status === "recognition_not_accepted"
                ? " 棄却済み"
                : status === "submit_an_application"
                ? " 申請中"
                : " --"}
            </p>
          </div>
        </div>

        <div className="flex justify-between mb-4 flex-wrap sm:flex-nowrap">
          <div className="w-full sm:w-1/2 border p-4 mr-0 sm:mr-2 mb-4 sm:mb-0">
            <div className="h-99% md:h-[90%] lg:h-80 xl:h-80  bg-gray-100 flex items-center justify-center">
              {evidenceImage ? (
                <img src={evidenceImage} alt="Evidence" className="max-h-full" />
              ) : (
                <p>エビデンス画像</p>
              )}
            </div>
          </div>

          <div className="w-full sm:w-1/2 border p-4 ml-0 sm:ml-2">
            <div className="h-99% md:h-[90%] lg:h-80 xl:h-80 bg-gray-100 flex items-center justify-center">
              {reasonForRejection ? (
                <p className="px-4">{reasonForRejection}</p>
              ) : (
                <p>却下理由</p>
              )}
            </div>
          </div>
        </div>

        <div className="lg:flex xl:flex md:flex justify-between flex-wrap sm:flex-nowrap">
          <div className="text-sm text-gray-700 justify-start sm:justify-center">
            <p>申請日: {formatDisplayDate(applicationDate)}</p>
            <p>承認日: {formatDisplayDate(approveDate)}</p>
            <p>棄却日: {formatDisplayDate(dismissalDate)}</p>
          </div>
          <div className="justify-end sm:justify-center">
            <div className="mt-4 flex flex-wrap gap-4">
              <button
                className="bg-[#8E8E93] text-white font-semibold px-4 py-2 rounded-md"
                onClick={() =>
                  onUpdateStatus(appraisalid, shopid, "assessment_confirmation")
                }
              >
                査定確認
              </button>
              {status != "recognition_not_accepted" && (<button
                className="bg-[#f25f2a] text-white font-semibold px-4 py-2 rounded-md"
                onClick={() => handleConfirmation("rejection")}
              >
                棄却
              </button>)}
              
              {status != "recognition_applied" && (<button
                className="notice-edit text-white font-semibold px-4 py-2 rounded-md"
                onClick={() => handleConfirmation("approval")}
              >
                承認
              </button>)}
              
            </div>
            {(status === "submit_an_application" || status === "recognition_not_accepted") && (
              <div className="mb-4">
                <div className="mb-4">
                  <div className="text-sm text-gray-700 justify-start sm:justify-center mt-2">
                    却下承認年月日指定
                  </div>
                  <DatePicker
                    className="w-full border border-gray-300 rounded-md p-2 mt-2"
                    value={selectedDate}
                    format="YYYY/MM/DD"
                    placeholder="YYYY/MM/DD"
                    onChange={handleDateChange}
                    inputReadOnly
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {isOpenDelete && (
          <div className="w-screen h-screen fixed bg-transparent top-0 left-0 z-[80]">
            <div className="w-full h-full flex items-center justify-center">
              <div className="bg-white flex flex-col items-center justify-center gap-4 w-4/12 h-[20vh] p-4 shadow-sm shadow-[#f25f2a] border-[#f25f2a] border">
                <h1>
                  {actionType === "approval"
                    ? "この却下申請を承認してもよろしいですか?"
                    : "この却下申請を棄却してもよろしいですか?"}
                </h1>
                <div className="flex items-center gap-4">
                  <Button variant="contained" onClick={handleActionConfirm}>
                    はい
                  </Button>
                  <Button variant="contained" onClick={handleActionCancel}>
                    いいえ
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </ConfigProvider>
  );
};

export default AppraisalCard;