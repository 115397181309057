const generateBill = (
  assessmentEx,
  assessmentNonEx,
  rejectionLastMonthEx,
  rejectionLastMonthNonEx,
  referral,
  introduction,
  nonExAmount,
  rejectionNonExAmount,
  exAmount,
  rejectionExAmount,
  japaneesMonth,
) => {
  let billItems = [];

  if (assessmentEx && referral) {
    const exclusiveItem = {
      description: 'CTN車一括査定（独占）',
      volume: assessmentEx,
      unitPrice: referral,
      total: exAmount >= 0 ? `¥${exAmount}` : `-¥${Math.abs(exAmount)}`
    };

    billItems = [...billItems, exclusiveItem];
  }

  if (rejectionLastMonthEx) {
    const rejectionExclusiveItem = {
      description: `${japaneesMonth}却下分（独占)`,
      volume: rejectionLastMonthEx,
      unitPrice: referral,
      total: rejectionExAmount >= 0 ? `¥${rejectionExAmount}` : `-¥${Math.abs(rejectionExAmount)}`
    };

    billItems = [...billItems, rejectionExclusiveItem];
  }

  if (assessmentNonEx) {
    const nonexclusiveItem = {
      description: 'CTN車一括査定',
      volume: assessmentNonEx,
      unitPrice: introduction,
      total: nonExAmount >= 0 ? `¥${nonExAmount}` : `-¥${Math.abs(nonExAmount)}`
    };

    billItems = [...billItems, nonexclusiveItem];
  }

  if (rejectionLastMonthNonEx) {
    const nonexclusiveRejectionItem = {
      description: `${japaneesMonth}却下分`,
      volume: rejectionLastMonthNonEx,
      unitPrice: introduction,
      total: rejectionNonExAmount >= 0 ? `¥${rejectionNonExAmount}` : `-¥${Math.abs(rejectionNonExAmount)}`

    };

    billItems = [...billItems, nonexclusiveRejectionItem];
  }

  return billItems;
};

export { generateBill };
