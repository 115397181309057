import { BASE_URL_2 } from "../config/config";
import { httpDelete, httpGet, httpPatch } from "../config/httpService";

const URL = `${BASE_URL_2}/shop`;

export const getShopById = async (shopId) => {
  try {
    const response = await httpGet(`${URL}?id=${shopId}`);
    const data = response?.data;
    return data;
  } catch (error) {
    throw error?.response?.data?.error;
  }
};

export const getShopConditionById = async (shopId, type) => {
  try {
    const response = await httpGet(
      `${URL}/condition?shopId=${shopId}&subscriptionType=${type}`
    );
    const data = response?.data;
    return data;
  } catch (error) {
    throw error?.response?.data?.error;
  }
};
export const updateShopCondtions = async (condiationData) => {
  try {
    const response = await httpPatch(`${URL}/condition`, condiationData);
    const data = response?.data;
    return data;
  } catch (error) {
    throw error?.response?.data?.error;
  }
};

export const updateShopBuyer = async (id, updatedData) => {
  try {
    const response = await httpPatch(`${URL}`, updatedData);
    const data = response?.data;
    return data;
  } catch (error) {
    throw error?.response?.data?.error;
  }
};

export const updateShopCondtion = async (updatedData) => {
  try {
    const response = await httpPatch(`${URL}/condition`, updatedData);
    const data = response?.data;
    return data;
  } catch (error) {
    throw error?.response?.data?.error;
  }
};

export const deleteShop = async (shopId) => {
  try {
    const response = await httpDelete(`${URL}?id=${shopId}`);
    const data = response?.data;
    return data;
  } catch (error) {
    throw error?.response?.data?.error;
  }
};
