import Plus from "./icon_plus";
import { useEffect, useState } from "react";
import CloudSyncIcon from "@mui/icons-material/CloudSync";

export default function Button(props) {
  const [active, setActive] = useState(
    props.on ? true : props.off ? false : false
  );

  useEffect(()=>{
    props.clearStatus && setActive(false) 
  },[props.clearStatus])

  const toggle_color = () => {
    if (!props.has_state) return;
    setActive(!active);
  };
  const on_click = (e) => {
    if (props.onClick) props.onClick(e);
    toggle_color();
  };
  const icon = () => {
    if (props.plus) return <Plus />;
    if (props.cloud) return <CloudSyncIcon style={{ marginRight: "4px" }} />;
    return props.label;
  };

  const iconV2 = () => {
    if (props.plus) return <Plus />;
    if (props.cloud) return <CloudSyncIcon style={{ marginRight: "4px" }} />;
  };

  return (
    <>
      {props.circle ? (
        <>
          <div className="button_wrapper">
            <div className="pad"></div>
            <button
              onClick={on_click}
              className={`font_button_label circle flex items-center justify-center ${props.color} on ${
                props.xs
                  ? "xs"
                  : props.small
                  ? "small"
                  : props.middle
                  ? "middle"
                  : props.small2
                  ? "small2"
                  : "normal"
              }`}
            >
              {icon()}
            </button>
            <div className="pad"></div>
          </div>
        </>
      ) : (
        <div className="button_wrapper">
          <div className="pad"></div>
          <button
            onClick={on_click}
            className={`font_button_label ${props.color} ${
              active ? "on" : "off"
            } ${
              props.xs
                ? "xs"
                : props.small
                ? "small"
                : props.middle
                ? "middle"
                : props.small2
                ? "small2"
                : "normal"
            }`}
          >
            <span className="button-label">
              <div className="icon-label-wrapper" sx={{ display: "flex" }}>
                {iconV2()} {props.label}
              </div>
            </span>
          </button>
          <div className="pad"></div>
        </div>
      )}
    </>
  );
}
