import { create } from "zustand";

const initialState = {
  clearImage: false,
 
};

export const useBuyerStore = create((set) => ({
  ...initialState,
  setClearImage: (clearImage) => set((state) => ({ clearImage })),
}));



