import { useState } from "react";
import Logo from "../../components/Logo";
import Button from "../../components/button";
import { useBackend } from "../../hooks/useBackend";
import AppraisalList from "./AppraisalList";
import Stack from "@mui/material/Stack";
import MainView from "./MainView";
import Edit from "./Edit";
import { Link } from "react-router-dom";
import Logout from "../auth/logout";

export default function AppraisalView() {
  const api = useBackend();
  const [editing, setEditing] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const editClose = () => {
    setEditing(!editing);
  };

  const generate_appraisal_list_report = () => {
    setDownloading(true);

    api.gen_appraisal_csv().then((e) => {
      setTimeout(() => {
        setDownloading(false);
        // Create a hidden link to download the file
        const link = document.createElement("a");
        link.href =
          "https://assessed-appraisals-csv.s3.ap-northeast-1.amazonaws.com/data.csv";
        link.download = "data.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }, 1000);
    });
  };

  return (
    <div className="appraisals">
      <div className="view_header flex">
        <div className="flex">
          <Logo />
          <h1 className="space_around">査定一覧</h1>
          <Button color={"main"} plus circle onClick={editClose} />
          {editing && <Edit onClose={editClose} refresh={editing} />}
        </div>
        <div className="flex">
            <Link to={`/console/dashboard?tab=${0}`}  state={{ previousPage: '/console/appraisals' }} className="space_right">
              <Button label="管理者ポータル" color={"sub"} on />
            </Link>
          <Stack direction="row" spacing={2} sx={{}} alignItems="center">
            <Stack direction={{ lg: "column", xl: "row" }} spacing={1} sx={{}}>
              <Logout />
              <Link to="/console/password-change">
                <Button label="パスワード変更" color={"sub"} on />
              </Link>
            </Stack>
            <Stack direction={{ lg: "column", xl: "row" }} spacing={1} sx={{}}>
              <Link to="/console/register">
                <Button label="新規ユーザー登録" color={"sub"} on />
              </Link>
              {/* TODO : reenable once report query is stable
              <Link to="/console/report">
                <Button label="レポートへ" color={"sub"} on />
              </Link> */}
            </Stack>
            <Stack direction={{ lg: "column", xl: "row" }} spacing={1} sx={{}}>
              <div>
                <Button
                  label="CSV出力"
                  color={"sub"}
                  on
                  onClick={generate_appraisal_list_report}
                  disabled={downloading}
                />
              </div>
            </Stack>
          </Stack>
        </div>
      </div>
      <MainView />
    </div>
  );
}
