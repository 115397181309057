import { useState, useEffect } from "react";

export default function DropDown(props) {
  const [selected, setSelected] = useState(props.selected || "");
  const [isTyped, setIsTyped] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState(props.options || []);

  useEffect(() => {
    setSelected(props.selected || "");
  }, [props.selected]);

  useEffect(() => {
    if (props.id === "car_maker") {
      setDropdownOptions(props.options || []);
    } else {
      setDropdownOptions(props.options || []);
    }
  }, [props.options, props.id]);

  const generateYearOptions = () => {
    const japaneseDateYears = [
      { value: "令和5(2023)", label: "令和5(2023)" },
      { value: "令和4(2022)", label: "令和4(2022)" },
      { value: "令和3(2021)", label: "令和3(2021)" },
      { value: "令和2(2020)", label: "令和2(2020)" },
      { value: "令和1(2019)", label: "令和1(2019)" },
      { value: "平成30(2018)", label: "平成30(2018)" },
      { value: "平成29(2017)", label: "平成29(2017)" },
      { value: "平成28(2016)", label: "平成28(2016)" },
      { value: "平成27(2015)", label: "平成27(2015)" },
      { value: "平成26(2014)", label: "平成26(2014)" },
      { value: "平成25(2013)", label: "平成25(2013)" },
      { value: "平成24(2012)", label: "平成24(2012)" },
      { value: "平成23(2011)", label: "平成23(2011)" },
      { value: "平成22(2010)", label: "平成22(2010)" },
      { value: "平成21(2009)", label: "平成21(2009)" },
      { value: "平成20(2008)", label: "平成20(2008)" },
      { value: "平成19(2007)", label: "平成19(2007)" },
      { value: "平成18(2006)", label: "平成18(2006)" },
      { value: "平成17(2005)", label: "平成17(2005)" },
      { value: "平成16(2004)", label: "平成16(2004)" },
      { value: "平成15(2003)", label: "平成15(2003)" },
      { value: "平成14(2002)", label: "平成14(2002)" },
      { value: "平成13(2001)", label: "平成13(2001)" },
      { value: "平成12(2000)", label: "平成12(2000)" },
      { value: "平成11(1999)", label: "平成11(1999)" },
      { value: "平成10(1998)", label: "平成10(1998)" },
      { value: "平成9(1997)", label: "平成9(1997)" },
      { value: "平成8(1996)", label: "平成8(1996)" },
      { value: "平成7(1995)", label: "平成7(1995)" },
      { value: "平成6(1994)", label: "平成6(1994)" },
      { value: "平成5(1993)", label: "平成5(1993)" },
      { value: "平成4(1992)", label: "平成4(1992)" },
      { value: "平成3(1991)", label: "平成3(1991)" },
      { value: "平成2(1990)", label: "平成2(1990)" }
    ];

    return [{ value: "(指定なし)", label: "(指定なし)" }, ...japaneseDateYears];
  };

  const isManualInputAllowed = (fieldId) => {
    const manualInputFields = ["car_type", "car_model_year", "car_traveled_distance", "grade"];
    return manualInputFields.includes(fieldId);
  };

  const shouldUseCustomYearOptions = () => {
    return props.id === "car_model_year" && 
           ((props.parentCarTypeIsTyped && props.options?.length <= 1));
  };

  const getDisplayOptions = () => {
    if (props.id === "car_maker") {
      return dropdownOptions;
    }

    if (shouldUseCustomYearOptions()) {
      return generateYearOptions();
    }

    if (props.id === "grade" && props.shouldHideOptions) {
      return [];
    }

    return dropdownOptions;
  };

  const handleClear = () => {
    setSelected("");
    setIsTyped(false);
    props.onChange?.({ [props.id]: "" });
    
    if (props.id === "car_type") {
      props.onClear?.(["car_model_year", "grade"]);
      props.onTypeChange?.(false);
    } else if (props.id === "car_model_year") {
      props.onClear?.(["grade"]);
      props.onTypeChange?.(false);
    } else if (props.id === "grade") {
      props.onTypeChange?.(false);
    }
  };

  const onInputChange = (e) => {
    const value = e.target.value;
    
    if (props.id === "car_maker") {
      const isValidOption = dropdownOptions?.some(opt => opt.value === value);
      if (!isValidOption) {
        setSelected(props.selected || "");
        return;
      }
      props.onClear?.(["car_type", "car_model_year", "grade"]);
    }

    const isManualInput = !dropdownOptions?.some(opt => opt.value === value);
    setSelected(value);

    if (isManualInputAllowed(props.id)) {
      setIsTyped(isManualInput);

      if (props.id === "car_type" && isManualInput) {
        props.onClear?.(["car_model_year", "grade"]);
        props.onTypeChange?.(true);
      }
      
      if (props.id === "car_model_year" && isManualInput) {
        props.onClear?.(["grade"]);
        props.onTypeChange?.(true);
      }

      if (props.id === "grade" && isManualInput) {
        props.onTypeChange?.(true);
      }
    }

    props.onChange?.({ [props.id]: value });
  };

  const showClearButton = isManualInputAllowed(props.id) && selected;

  const DropdownIcon = () => (
    <div className={`absolute right-0 top-0 bottom-0 w-8 pointer-events-none flex items-center justify-center text-gray-400 ${showClearButton ? 'hidden' : ''}`}>
      <svg 
        className="w-4 h-4 fill-current" 
        viewBox="0 0 20 20"
      >
        <path 
          fillRule="evenodd" 
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" 
          clipRule="evenodd"
        />
      </svg>
    </div>
  );

  return (
    <div className={`input-norm relative ${props.sparse ? "sparse" : props.narrow ? "narrow" : ""}`}>
       <style>
        {`
          /* Remove dropdown arrow from input */
          input::-webkit-calendar-picker-indicator {
            display: none !important;
            -webkit-appearance: none;
          }
          input[list]::-webkit-calendar-picker-indicator {
            display: none !important;
            -webkit-appearance: none;
          }
        `}
      </style>
      <span className="label font_input_label">{props.label}</span>
      <div className="relative">
        {props.id === "car_maker" || props.id === "car_traveled_distance" ? (
          <select
            className="dropdown font_input_content w-full appearance-none pr-8"
            value={selected}
            onChange={onInputChange}
            disabled={props.disabled}
          >
            {getDisplayOptions().map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ) : (
          <div className="relative flex items-center">
            <input
              type="text"
              list={getDisplayOptions().length > 0 ? props.id : undefined}
              className="dropdown font_input_content w-full pr-16"
              value={selected}
              onChange={onInputChange}
              disabled={props.disabled}
              placeholder={
                isManualInputAllowed(props.id)
                  ? "入力または選択"
                  : "選択してください"
              }
            />
            <div className="absolute right-0 flex items-center">
              {isManualInputAllowed(props.id) && selected && (
                <button
                  onClick={handleClear}
                  className="w-8 h-8 flex items-center justify-center text-gray-400 hover:text-gray-600 focus:outline-none"
                  type="button"
                  aria-label="Clear input"
                >
                  <span className="text-lg leading-none select-none">×</span>
                </button>
              )}
            </div>
          </div>
        )}
        <DropdownIcon />
      </div>
      {props.id !== "car_maker" && getDisplayOptions().length > 0 && (
        <datalist id={props.id}>
          {getDisplayOptions().map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </datalist>
      )}
    </div>
  );
}