import { Button, Spin } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAuditByIdQuery } from "../../app/services/audit.api";
import MainWrapper from "../main-wrapper";
import { TableLoadingSkeleton } from "../loading-screens";

const ChangeSection = ({ commit }) => {
  return (
    <div className="mb-8 p-4 border rounded bg-white shadow">
      <h2 className="text-lg font-bold mb-4">{commit?.columnName}</h2>
      <div className="space-y-4">
        <div className="flex justify-between w-full">
          <div className="w-1/2 pr-2">
            <div className="text-gray-600 mb-2">変更前の選択肢:</div>
            <div className="bg-gray-50 p-3 rounded border w-full max-h-40 overflow-y-auto">
              {commit?.InitialValues?.join("、") || "なし"}
            </div>
          </div>
          <div className="w-1/2 pl-2">
            <div className="text-gray-600 mb-2">変更後の選択肢:</div>
            <div className="bg-gray-50 p-3 rounded border w-full max-h-40 overflow-y-auto  ">
              {commit?.finalValues?.join("、") || "なし"}
            </div>
          </div>
        </div>

        {commit?.addedValues?.length > 0 && (
          <div>
            <div className="text-gray-600 mb-2">追加された項目:</div>
            <div className="bg-green-50 p-3 rounded border max-h-40 overflow-y-auto">
              {commit?.addedValues?.join("、") || "なし"}
            </div>
          </div>
        )}

        {commit?.removedValues?.length > 0 && (
          <div>
            <div className="text-gray-600 mb-2">削除された項目:</div>
            <div className="bg-red-50 p-3 rounded border max-h-40 overflow-y-auto">
              {commit?.removedValues?.join("、") || "なし"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const HistoryDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, error } = useGetAuditByIdQuery(id);

  const handleBack = () => {
    navigate(-1);
  };

  if (isLoading) {
    return (
      <MainWrapper>
        <TableLoadingSkeleton />
      </MainWrapper>
    );
  }

  if (error) {
    return (
      <MainWrapper>
        <div className="min-h-screen bg-gray-50 flex items-center justify-center text-red-600">
          エラーが発生しました
        </div>
      </MainWrapper>
    );
  }

  if (!data) return null;

  return (
    <MainWrapper>
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-4xl mx-auto p-4 bg-white shadow rounded">
          {/* Header Section */}
          <div className="flex justify-between mb-6">
            <div>
              <h1 className="text-xl font-bold mb-1">変更履歴詳細</h1>
              <div className="text-gray-600">
                {new Date(data.timestamp).toLocaleString("ja-JP", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                })}
              </div>
            </div>
            <div className="text-right">
              <div className="mb-1">変更者: {data.userName}</div>
              <div>買取店舗: {data.shopName}</div>
            </div>
          </div>

          {data.commits.map((commit, index) => (
            <ChangeSection
              key={`${commit.tableName}-${index}`}
              commit={commit}
            />
          ))}

          <div className="mt-6">
            <Button onClick={handleBack}>一覧に戻る</Button>
          </div>
        </div>
      </div>
    </MainWrapper>
  );
};

export default HistoryDetails;
